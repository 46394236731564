import * as Action from "../../constants/ActionTypes";
const initState = {
  Data: [],
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.GET_INVOICE_LIST: {
      return {
        ...state,
        Invoice: action.payload,
      };
    }
    case Action.GET_PROJETACCOUNTING_BY_PROJETID: {
      return {
        ...state,
        ProjetAccounting: action.payload,
      };
    }
    case Action.ADD_EMPLOYEE_RATES_PROJECT_ACCOUNTING: {
      var obj = {
        ...state,
        [action.payload[0].ProjectAccountungId]: {
          ...state[action.payload[0].ProjectAccountungId],
          employeeRatesProjectAccounting: action.payload,
        },
      };

      return {
        ...state,
        [action.payload[0].ProjectAccountingId]: {
          ...state[action.payload[0].ProjectAccountungId],
          employeeRatesProjectAccounting: action.payload,
        },
      };
    }
    case Action.ADD_RATE_BY_EMPLOYEE_CLASS_PROJECT_ACCOUNTING: {
      return {
        ...state,
        [action.payload[0].ProjectAccountingId]: {
          ...state[action.payload[0].ProjectAccountungId],
          rateByEmployeeClassProjectAccounting: action.payload,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default reducer;
