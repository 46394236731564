import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  SET_EMPLOYEE_DETAILS,
  SET_COMPANY_DETAILS,
  SET_COMPANY_REPRESENTATIVE_DETAILS,
  SET_COMPANY_REPRESENTATIVE_ADDRESS,
  SET_COMPANY_CLIENT_ADDRESS,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  SET_PROJECT_LIST,
  SET_PROJECT_LOCATIONS,
  SET_ALL_LOCATIONS,
  ADD_PROJECT_LOCATIONS,
  UPDATE_PROJECT_LOCATIONS,
  LOCALHOST,
  SET_PROJECT_BY_ID,
  SET_FORM_STATE,
  GENERIC_APP_FILE_UPLOAD_SET,
  UPLOAD_PUSH_IDENTIIFIER,
  GET_CLIENT_TYPES,
  GET_CURRENCY_DETAILS,
  GET_FORMTYPES_DETAILS,
  GET_EMPLOYEE_RATE,
  GET_HEADER_BY_PROJECT_ID,
  SET_AVAILABLE_SUFF_FOR_PROJECT,
  UPDATE_RISK_ANALYSIS_INNER_TABLE,
  GET_MANDATEPROJECTS_LIST,
} from "../../constants/ActionTypes";
import { batch } from "react-redux";
import _ from "lodash";
import * as Action from "./index";
import axios from "util/Api";
import React from "react";
import IntlMessages from "util/IntlMessages";
export const setForm = (form) => {
  return {
    type: SET_FORM_STATE,
    payload: form,
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
export const handleFileUpload = (name, file) => {
  return {
    type: GENERIC_APP_FILE_UPLOAD_SET,
    payload: { name, file },
  };
};

export const setProjectLocations = (locations) => {
  return {
    type: SET_PROJECT_LOCATIONS,
    payload: locations,
  };
};

export const AddProjectLocations = (location) => {
  return {
    type: ADD_PROJECT_LOCATIONS,
    payload: location,
  };
};

export const UpdateProjectLocations = (location) => {
  return {
    type: UPDATE_PROJECT_LOCATIONS,
    payload: location,
  };
};

export const getProjectLocationsById = (id) => async (dispatch) => {
  return axios
    .get(`${LOCALHOST}api/Project/GetLocationByProjectId?id=${id}`)
    .then((locations) => {
      return dispatch({
        type: SET_PROJECT_LOCATIONS,
        payload: locations.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });
    });
};

export const getAllLocations = () => async (dispatch) => {
  return axios
    .get(`${LOCALHOST}api/Project/GetAllLocations`)
    .then((locations) => {
      return dispatch({
        type: SET_ALL_LOCATIONS,
        payload: locations.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });
    });
};

export const setDataFromLocalStorage = (payload) => {
  return {
    type: SET_PROJECT_BY_ID,
    payload,
  };
};
export const GetEmployeeDetails = (values) => async (dispatch) => {
  //    console.log('Actions eomployee details********************************'+employeeDetails.data.Payload.Department.map(a=>a.DepartementName))
  return axios
    .get(`${LOCALHOST}api/Lookup/GetEmployeeDetails`)
    .then((employeeDetails) => {
      return dispatch({
        type: SET_EMPLOYEE_DETAILS,
        payload: employeeDetails.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};

export const GetClientTypes = () => async (dispatch) => {
  return axios
    .get(`${LOCALHOST}api/Lookup/Client/type`)
    .then((response) => {
      console.log("in response");
      console.log(response);
      return dispatch({
        type: GET_CLIENT_TYPES,
        payload: response.data.Payload,
      });
    })
    .catch((err) => {
      console.log("Error");
      const errors = err.StatusDesc;
      return dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};
export const GetCurrencyDetails = () => async (dispatch) => {
  return axios
    .get(`${LOCALHOST}api/Lookup/GetCurrencyList`)
    .then((response) => {
      return dispatch({
        type: GET_CURRENCY_DETAILS,
        payload: response.data.Payload,
      });
    })
    .catch((err) => {
      console.log("Error");
      const errors = err.StatusDesc;
      return dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};

export const GetFormTypes = () => async (dispatch) => {
  return axios
    .get(`${LOCALHOST}api/Lookup/FormTypes`)
    .then((response) => {
      console.log("Response Form Types");
      console.log(response);
      return dispatch({
        type: GET_FORMTYPES_DETAILS,
        payload: response.data.Payload,
      });
    })
    .catch((err) => {
      console.log("Error");
      const errors = err.StatusDesc;
      return dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};
// GetCompanyDetails
// SET_COMPANY_DETAILS
export const GetCompanyDetails = (values) => async (dispatch) => {
  return axios
    .get(`${LOCALHOST}api/Lookup/GetCompanyDetails`)
    .then((employeeDetails) => {
      dispatch({
        type: SET_COMPANY_DETAILS,
        payload: employeeDetails.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};

export const GetCompanyRepresentative =
  (customerNumber) => async (dispatch) => {
    //const customerNumber = 4411001;
    return axios
      .get(`${LOCALHOST}api/Lookup/Customer/Contact/${customerNumber}`)
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: SET_COMPANY_REPRESENTATIVE_DETAILS,
            payload: res.data.Payload,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch({
          type: FETCH_ERROR,
          payload: errors,
        });
        console.log("Error****:", errors);
      });
  };

export const RepresentativeDetails =
  (contactPersonName) => async (dispatch) => {
    // const contactPersonName = 'Daniel Champagne';
    try {
      const representativeAddressDetails = await axios.get(
        `${LOCALHOST}api/Lookup/Customer/Addresses/${contactPersonName}`
      );
      console.log(
        "customer contact address representative********************************" +
          representativeAddressDetails.data.Payload
      );
      if (representativeAddressDetails.data.Payload) {
        dispatch({
          type: SET_COMPANY_REPRESENTATIVE_ADDRESS,
          payload: representativeAddressDetails.data.Payload,
        });
      }
    } catch (err) {
      const errors = err.StatusDesc;

      dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    }
  };

// Client Address
export const ClientAddressDetails = (ClientNumber) => async (dispatch) => {
  try {
    const ClientAddressDetails = await axios.get(
      `${LOCALHOST}api/Lookup/Customer/ClientAddresses/${ClientNumber}`
    );
    console.log(
      "customer contact address representative********************************" +
        ClientAddressDetails.data.Payload
    );
    if (ClientAddressDetails.data.Payload) {
      dispatch({
        type: SET_COMPANY_CLIENT_ADDRESS,
        payload: ClientAddressDetails.data.Payload,
      });
    }
  } catch (err) {
    const errors = err.StatusDesc;

    dispatch({
      type: FETCH_ERROR,
      payload: errors,
    });

    console.log("Error****:", errors);
  }
};

export const GetEmployeeRate = (values) => async (dispatch) => {
  let locale = JSON.parse(localStorage.getItem("selectedlanguage"));

  return axios
    .get(
      `${LOCALHOST}api/Lookup/GetByType?type=EmployeeClass&language=${locale?.locale}`
    )
    .then((employeeRate) => {
      dispatch({
        type: GET_EMPLOYEE_RATE,
        payload: employeeRate.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};

export const SaveAsDraft = (values) => async (dispatch) => {
  try {
    const obj = {
      ...values,
      IdProjet: parseInt(values.IdProjet),
      ClientRepresentant: values.ClientRepresentant,
      // ? Array.isArray(values.ClientRepresentant) &&
      //   values.ClientRepresentant.length
      //   ? values.ClientRepresentant[0]
      //   : values.ClientRepresentant
      // : "",
      // InstructionsParticuliereFeuillesDeTemps:
      //   values.InstructionsParticuliereFeuillesDeTemps === true ? 1 : null,
      // InstructionsParticulierePieceJustificatives:
      //   values.InstructionsParticulierePieceJustificatives === true ? 1 : null,
      // InstructionsParticuliereFactureSommaire:
      //   values.InstructionsParticuliereFactureSommaire === true ? 1 : null,
      // InstructionsParticuliereAutres:
      //   values.InstructionsParticuliereAutres === true ? 1 : null,
      // InstructionsParticuliereFactureParPhase:
      //   values.InstructionsParticuliereFactureParPhase === true ? 1 : null,
      // InstructionsParticuliereMentionSpeciale:
      //   values.InstructionsParticuliereMentionSpeciale === true ? 1 : null,
    };
    const res = await axios.post(`${LOCALHOST}api/Project/Submit`, obj);
    if (res.data.IsSuccess) {
      // dispatch(Action.resetPhaseOffreForm());
      dispatch(Action.fetchSuccess(<IntlMessages id="Project.Success" />));
      dispatch(Action.commonUpload("Project", parseInt(values.IdProjet)));
      return { success: true, id: res.data.Payload };
    } else {
      dispatch(
        Action.fetchError(
          res.data.ErrorMessage,
          <IntlMessages id="Project.Failed" />
        )
      );
      return { success: false, id: null };
    }
  } catch (err) {
    dispatch(
      Action.fetchError(err.message, <IntlMessages id="Project.Failed" />)
    );
    return { success: false, id: null };
  }
};

export const saveProject = (values) => async (dispatch) => {
  try {
    values.IdProjet = 0;
    const res = await axios.post(`${LOCALHOST}api/Project/Create`, values);
    if (res.data.IsSuccess) {
      // dispatch(Action.resetPhaseOffreForm());
      dispatch(Action.commonUpload("Project", parseInt(values.IdProjet)));
      dispatch(Action.fetchSuccess(<IntlMessages id="Project.Success" />));
      return { success: true, id: res.data.Payload };
    } else {
      dispatch(
        Action.fetchError(
          res.data.ErrorMessage,
          <IntlMessages id="Project.Failed" />
        )
      );
      return { success: false, id: null };
    }
  } catch (err) {
    dispatch(
      Action.fetchError(err.message, <IntlMessages id="Project.Failed" />)
    );
    return { success: false, id: null };
  }
};

export const createMandate = (projectId, projSuffix) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${LOCALHOST}api/Project/Mandate?id=${parseInt(
        projectId
      )}&projectSuffix=${projSuffix}`
    );
    if (res.data.IsSuccess) {
      dispatch(Action.fetchSuccess(<IntlMessages id="ProjectMan.Create" />));
      return { success: true, id: res.data.Payload };
    } else {
      dispatch(
        Action.fetchError(
          res.data.ErrorMessage,
          <IntlMessages id="ProjectMan.Failed" />
        )
      );
      return { success: false, id: null };
    }
  } catch (err) {
    dispatch(
      Action.fetchError(err.message, <IntlMessages id="ProjectMan.Failed" />)
    );
    return { success: false, id: null };
  }
};

export const UpdateLocations = (payload) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${LOCALHOST}api/Project/UpdateLocations`,
      payload
    );
    if (res.data.IsSuccess) {
      if (res.data.Payload) {
        dispatch(Action.fetchSuccess(<IntlMessages id="Common.Update" />));
      } else {
        dispatch(
          Action.fetchError(
            res.data.ErrorMessage,
            <IntlMessages id="Common.UpdateError" />
          )
        );
      }
      return { success: true, id: res.data.Payload };
    } else {
      dispatch(
        Action.fetchError(
          res.data.ErrorMessage,
          <IntlMessages id="Common.UpdateError" />
        )
      );
      return { success: false, id: null };
    }
  } catch (err) {
    dispatch(Action.fetchError(err.message, "Update Error!"));
  }
};

export const UpdateProject =
  (values, projid, id) => async (dispatch, state) => {
    try {
      const { table } = state().RiskAnalysis;
      const obj = {
        ...values,
        IdProjet: parseInt(values.IdProjet),
        // InstructionsParticuliereFeuillesDeTemps:
        //   values.InstructionsParticuliereFeuillesDeTemps === true ? 1 : null,
        // InstructionsParticulierePieceJustificatives:
        //   values.InstructionsParticulierePieceJustificatives === true ? 1 : null,
        // InstructionsParticuliereFactureSommaire:
        //   values.InstructionsParticuliereFactureSommaire === true ? 1 : null,
        // InstructionsParticuliereAutres:
        //   values.InstructionsParticuliereAutres === true ? 1 : null,
        // InstructionsParticuliereFactureParPhase:
        //   values.InstructionsParticuliereFactureParPhase === true ? 1 : null,
        // InstructionsParticuliereMentionSpeciale:
        //   values.InstructionsParticuliereMentionSpeciale === true ? 1 : null,
        DepartementIdDirecteur: id,
        TypeDeClientId: parseInt(values.TypeDeClientId),
        LevelSecurity: parseInt(values.LevelSecurity),
        Company:
          values != null && values.Company != null
            ? parseInt(values.Company)
            : null,
        ClientRepresentant: values.ClientRepresentant,
        // ? Array.isArray(values.ClientRepresentant) &&
        //   values.ClientRepresentant.length
        //   ? values.ClientRepresentant[0]
        //   : values.ClientRepresentant
        // : "",
        SupplierIdentification: table,
        RevueAvantSignature: values.RevueAvantSignature == true ? 1 : 0,
      };

      const res = await axios.put(`${LOCALHOST}api/Project/Update`, obj);
      if (res.data.IsSuccess) {
        if (res.data.Payload) {
          dispatch(Action.fetchSuccess(<IntlMessages id="Common.Update" />));
          Promise.all([
            dispatch(Action.commonUpload("Project", parseInt(values.IdProjet))),
            dispatch(Action.GetHeaderByProjectId(values.IdProjet)),
            // dispatch(Action.GetProjectById(values.IdProjet)),
          ]).then(() => {});
        } else {
          dispatch(
            Action.fetchError(
              res.data.ErrorMessage,
              <IntlMessages id="Common.UpdateError" />
            )
          );
        }
        return { success: true, id: res.data.Payload };
      } else {
        dispatch(
          Action.fetchError(
            res.data.ErrorMessage,
            <IntlMessages id="Common.UpdateError" />
          )
        );
        return { success: false, id: null };
      }
      // return axios
      //   .put(`${LOCALHOST}api/Project/Update`, obj)
      //   .then((res) => {
      //     if (res.data.Payload) {
      //       dispatch(Action.fetchSuccess("Sucessfully Update"));

      //       Promise.all([
      //         dispatch(Action.commonUpload("Project", parseInt(values.IdProjet))),
      //         dispatch(Action.GetHeaderByProjectId(values.IdProjet)),
      //         // dispatch(Action.GetProjectById(values.IdProjet)),
      //       ]).then(() => {});
      //     } else {
      //       dispatch(Action.fetchError(res.data.ErrorMessage, "Update Error!"));
      //     }
      //   })
      //   .catch((error) => {
      //     dispatch(Action.fetchError(error.message, "Update Error!"));
      //   });
    } catch (err) {
      dispatch(Action.fetchError(err.message, "Update Error!"));
    }
  };

export const getAllProjects = (values) => async (dispatch) => {
  try {
    const AllProjectList = await axios.get(`${LOCALHOST}api/Project/GetAll`);
    if (AllProjectList.data.Payload) {
      dispatch({
        type: SET_PROJECT_LIST,
        payload: AllProjectList.data.Payload,
      });
    } else {
      //console.log("response from server SSSSSSSSSSSSSSSSSSSSSSSSSSSSSS" + res)

      dispatch({
        type: FETCH_ERROR,
        payload: AllProjectList.data.ErrorMessage,
      });
      dispatch({
        type: HIDE_MESSAGE,
      });
    }
  } catch (error) {
    const errors = error;

    dispatch({
      type: FETCH_ERROR,
      payload: errors,
    });

    console.log("Error****:", errors);
  }
};

export const DeleteProject = (id) => async (dispatch) => {
  try {
    const Deleted = await axios.delete(`${LOCALHOST}api/Project/${id}`);
    if (Deleted.data.ErrorMessage) {
      dispatch({
        type: FETCH_ERROR,
        payload: Deleted.data.ErrorMessage,
      });
      dispatch({
        type: HIDE_MESSAGE,
      });
    } else {
      dispatch({
        type: SHOW_MESSAGE,
        payload: <IntlMessages id="Common.SuccessDelete" />,
      });

      dispatch({
        type: HIDE_MESSAGE,
      });
    }
  } catch (error) {
    const errors = error.data.ErrorMessage;

    dispatch({
      type: FETCH_ERROR,
      payload: errors,
    });

    console.log("Error****:", errors);
  }
};

export const GetHeaderByProjectId = (id) => async (dispatch) => {
  try {
    if (id && id > 0) {
      const headerDetails = await axios.get(
        `${LOCALHOST}api/Project/GetHeaderByProjectId/${id}`
      );
      if (headerDetails.data.Payload) {
        dispatch({
          type: GET_HEADER_BY_PROJECT_ID,
          payload: headerDetails.data.Payload,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: headerDetails.data.ErrorMessage,
        });
        dispatch({
          type: HIDE_MESSAGE,
        });
      }
    }
  } catch (error) {
    const errors = error;
    dispatch({
      type: FETCH_ERROR,
      payload: errors,
    });
  }
};

export const GetProjectById =
  (id, resetDragger = true) =>
  async (dispatch) => {
    try {
      const getProject = await axios.get(`${LOCALHOST}api/Project/${id}`);
      if (resetDragger) dispatch(Action.resetWholeFormInDragger("Project"));
      if (getProject.data.Payload) {
        getProject.data.Payload.TypeDeClientId =
          getProject.data.Payload.TypeDeClientId.toString();
        getProject.data.Payload.LevelSecurity =
          getProject.data.Payload.LevelSecurity.toString();
        getProject.data.Payload.Company = getProject.data.Payload.Company
          ? getProject.data.Payload.Company.toString()
          : null;
        dispatch({
          type: SET_PROJECT_BY_ID,
          payload: getProject.data.Payload,
        });
        let attachement = getProject.data.Payload.lstAttachment;
        let arr = getProject.data.Payload.SupplierIdentification.map((v, k) => {
          return {
            ...v,
            index: k,
          };
        });
        Promise.all([
          batch(() => {
            dispatch(Action.handleGetUploads(attachement, "Project"));
            dispatch(UpdateInnerFormRiskAnalysis(arr));
          }),
        ]);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: getProject.data.ErrorMessage,
        });
        dispatch({
          type: HIDE_MESSAGE,
        });
      }
    } catch (error) {
      const errors = error;

      dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    }
  };

export const GetProjectSuffixForMandate = (projectNo) => async (dispatch) => {
  return axios
    .get(`${LOCALHOST}api/Project/GetProjectSuffixForMandate/${projectNo}`)
    .then((projectSuff) => {
      dispatch({
        type: SET_AVAILABLE_SUFF_FOR_PROJECT,
        payload: projectSuff.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });
      console.log("Error****:", errors);
    });
};

export const UpdateInnerFormRiskAnalysis = (payload) => {
  return {
    type: UPDATE_RISK_ANALYSIS_INNER_TABLE,
    payload,
  };
};

// export const editInnerTableRiskAnalysis = (payload) => {
//   return {
//     type: Action.RISK_ANALYSIS_EDIT_INNER_TABLE,
//     payload,
//   };
// };

export const resetCreateForm = () => {
  return {
    type: SET_PROJECT_BY_ID,
    payload: null,
  };
};
export const resetHeaderDetails = () => {
  return {
    type: GET_HEADER_BY_PROJECT_ID,
    payload: null,
  };
};
export const pushFileUpload = (name, file) => {
  return {
    type: UPLOAD_PUSH_IDENTIIFIER,
    payload: { name, file },
  };
};
export const currencyFormat = (num) => {
  if (num === null) {
    return 0;
  } else {
    return `${num.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
};

export const GetProjectMandateList =
  (projectNo, locale) => async (dispatch) => {
    return axios
      .get(
        `${LOCALHOST}api/Project/GetMandateListByProjectNo/${projectNo}/${locale?.locale}`
      )
      .then((res) => {
        dispatch({
          type: GET_MANDATEPROJECTS_LIST,
          payload: res.data.Payload,
        });
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch({
          type: FETCH_ERROR,
          payload: errors,
        });
      });
  };

export const deleteMandateProject = (id, projectNo) => {
  return (dispatch) => {
    return axios
      .delete(`${LOCALHOST}api/Project/${id}`)
      .then((res) => {
        if (res.data.Payload) {
          return dispatch(GetProjectMandateList(projectNo));
        } else {
          return dispatch({
            type: SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
