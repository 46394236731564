import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  LOCALHOST,
  TOGGLE_LOADER,
  SET_LOADER,
  SET_PROJECT_ID,
  TOGGLE_COMPANY_LOCKUP_LOADER,
  TOGGLE_EMPLOYEE_LOCKUP_LOADER,
  GET_ALL_LOOKUPS_BY_LANGUAGE,
  GET_OFFICE_LOCATION,
  GET_BILLING_TECHNICIAN,
  GET_ALL_ACTIVEINACTIVE_LOOKUPS_BY_LANGUAGE,
} from "../../constants/ActionTypes";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import axios from "util/Api";
import React from "react";
import IntlMessages from "util/IntlMessages";

// https://www.lifewire.com/mime-types-by-content-type-3469108
// Follow the above link for all mime types (usefull if file types are increasing in future)
const ext = {
  ".png": "data:image/png;base64,",
  ".jpeg": "data:image/jpeg;base64,",
  ".jpg": "data:image/jpeg;base64,",
  ".pdf": "data:application/pdf;base64,",
  ".xls": "data:application/vnd.ms-excel;base64,",
  ".doc": "data:application/msword;base64,",
  ".docx": "data:application/msword;base64,",
  ".xlsx":
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  ".ppt": "data:application/vnd.ms-powerpoint;base64,",
  ".pptx":
    "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,",
  ".txt": "data:text/plain;base64,",
  ".msg": "data:application/vnd.ms-outlook;base64,",
  ".svg": "data:image/svg+xml;base64,",
  ".zip": "data:application/zip;base64,",
  ".url": "data:application/internet-shortcut;base64,",
};
export const toggleCompanyLockUpLoader = (payload) => {
  return {
    type: TOGGLE_COMPANY_LOCKUP_LOADER,
    payload,
  };
};
export const toggleEmployeeLockUpLoader = (payload) => {
  return {
    type: TOGGLE_EMPLOYEE_LOCKUP_LOADER,
    payload,
  };
};
export const setLoader = (payload) => {
  return {
    type: SET_LOADER,
    payload,
  };
};
export const fetchStart = () => {
  return (dispatch) => {
    NotificationManager.warning(
      "File Downloading",
      <IntlMessages id="Common.Download" />
    );
    dispatch({
      type: FETCH_START,
    });
  };
};

export const fileSizeExceededWarning = () => {
  return (dispatch) => {
    NotificationManager.warning(
      <IntlMessages id="Common.FileSizeExceededWarning" />,
      <IntlMessages id="Common.FileSizeExceededHeading" />
    );
    dispatch({
      type: FETCH_START,
    });
  };
};

export const fetchSuccess = (msg) => {
  return (dispatch) => {
    NotificationManager.success(
      msg,
      <IntlMessages id="Common.Success" />,
      5000
    );
    dispatch({
      type: FETCH_SUCCESS,
    });
  };
};

export const fetchError = (error, errorname) => {
  return (dispatch) => {
    NotificationManager.error(error, errorname, 5000);
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};
export const toggleLoader = () => {
  return {
    type: TOGGLE_LOADER,
  };
};
export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const globalException = (callback, params, name) => {
  return (dispatch) => {
    try {
      if (callback) {
        return callback(params);
      }
    } catch (exception) {
      dispatch(fetchError(exception.message, name));
    }
  };
};
export const UploadIdentifier = (name, id, file, identifier) => {
  return (dispatch) => {
    return axios
      .post(
        `${LOCALHOST}api/Attachment/${name}/Create/${id}/${identifier}`,
        file
      )
      .then((res) => {
        if (res.data.IsSuccess) {
          dispatch(fetchSuccess(<IntlMessages id="Common.FileUpload" />));
          return true;
        } else {
          console.log(res);
          dispatch(fetchError("", <IntlMessages id="Common.FileUploadFail" />));
          return false;
        }
      })
      .catch((Error) => {
        dispatch(
          fetchError(Error.message, <IntlMessages id="Common.FileUploadFail" />)
        );
        return false;
      });
  };
};
export const handleDownload = (val) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const download = (v) => {
      let url = v.substring(1);
      return axios
        .get(`${LOCALHOST}${url}`)
        .then((res) => {
          if (res.data.Payload.ResponceMessage === "File not exists.") {
            dispatch(
              fetchError(Error.message, res.data.Payload.ResponceMessage)
            );
            return;
          }
          var the_file = new Blob([window.atob(res.data.Payload.FileContent)], {
            type: "image/png",
            encoding: "utf-8",
          });
          var fr = new FileReader();
          fr.onload = function (oFREvent) {
            var v = oFREvent.target.result.split(",")[1]; // encoding is messed up here, so we fix it
            v = atob(v);
            var good_b64 = btoa(decodeURIComponent(escape(v)));
            good_b64 = `${
              ext[
                res.data.Payload.FileExtension &&
                  res.data.Payload.FileExtension.toLowerCase()
              ]
            }${good_b64}`;
            const link = document.createElement("a");
            link.href = good_b64;
            link.download = res.data.Payload.FileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            return good_b64;
          };
          fr.readAsDataURL(the_file);
          dispatch(fetchSuccess());
        })
        .catch((Error) => {
          dispatch(
            fetchError(Error.message, <IntlMessages id="Common.DownloadFail" />)
          );
        });
    };
    return dispatch(
      globalException(download, val, <IntlMessages id="Common.DownloadFail" />)
    );
  };
};

export const handleDownloadShortCut = (id) => {
  return (dispatch) => {
    return axios
      .get(`${LOCALHOST}api/Attachment/ProjectShortcut/${id}`)
      .then((res) => {
        if (res.data.Payload.ResponceMessage === "File not exists.") {
          dispatch(fetchError(Error.message, res.data.Payload.ResponceMessage));
          return;
        }
        var the_file = new Blob([window.atob(res.data.Payload.FileContent)], {
          type: "application/internet-shortcut",
          encoding: "utf-8",
        });
        var fr = new FileReader();
        fr.onload = function (oFREvent) {
          var v = oFREvent.target.result.split(",")[1]; // encoding is messed up here, so we fix it
          v = atob(v);
          var good_b64 = btoa(decodeURIComponent(escape(v)));
          good_b64 = `${
            ext[
              res.data.Payload.FileExtension &&
                res.data.Payload.FileExtension.toLowerCase()
            ]
          }${good_b64}`;
          const link = document.createElement("a");
          link.href = good_b64;
          link.download = res.data.Payload.FileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return good_b64;
        };
        fr.readAsDataURL(the_file);
        dispatch(fetchSuccess());
      })
      .catch((Error) => {
        dispatch(
          fetchError(Error.message, <IntlMessages id="Common.DownloadFail" />)
        );
      });
  };
};
export function objectKeysToCamelCase(snake_case_object) {
  var camelCaseObject = {};
  _.forEach(snake_case_object, function (value, key) {
    if (_.isPlainObject(value) || _.isArray(value)) {
      // checks that a value is a plain object or an array - for recursive key conversion
      value = objectKeysToCamelCase(value); // recursively update keys of any values that are also objects
    }
    camelCaseObject[_.camelCase(key)] = value;
  });
  return camelCaseObject;
}
export const setProjectID = (id) => {
  return {
    type: SET_PROJECT_ID,
    payload: id,
  };
};

export const GetAllLookupsByLanguage = () => async (dispatch) => {
  let locale = JSON.parse(localStorage.getItem("selectedlanguage"));
  return axios
    .get(
      `${LOCALHOST}api/Lookup/GetAllLookupsByLanguage?language=${locale?.locale}`
    )
    .then((invoiceDetails) => {
      return dispatch({
        type: GET_ALL_LOOKUPS_BY_LANGUAGE,
        payload: invoiceDetails.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });
    });
};

export const GetOfficeLocationLookUp = () => async (dispatch) => {
  return axios
    .get(`${LOCALHOST}api/Lookup/OfficeLocation`)
    .then((resp) => {
      return dispatch({
        type: GET_OFFICE_LOCATION,
        payload: resp.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });
    });
};

export const GetBillingTechnicianLookUp = () => async (dispatch) => {
  return axios
    .get(`${LOCALHOST}api/Lookup/BillingTechnician`)
    .then((resp) => {
      return dispatch({
        type: GET_BILLING_TECHNICIAN,
        payload: resp.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });
    });
};

export const GetAllActiveInActiveLookupsByLanguage = () => async (dispatch) => {
  let locale = JSON.parse(localStorage.getItem("selectedlanguage"));
  let includeInactive = true;
  return axios
    .get(
      `${LOCALHOST}api/Lookup/GetAllLookupsByLanguage?language=${locale?.locale}&includeInactive=${includeInactive}`
    )
    .then((invoiceDetails) => {
      return dispatch({
        type: GET_ALL_ACTIVEINACTIVE_LOOKUPS_BY_LANGUAGE,
        payload: invoiceDetails.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: FETCH_ERROR,
        payload: errors,
      });
    });
};
