import * as Action from "../../constants/ActionTypes";
import { INIT_URL } from "../../constants/ActionTypes";

const INIT_STATE = {
  //Data: null,
  ProjectManagerHistory: "",
  CustomerSurveryAttachements: [],
};
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }
    case Action.FILE_UPLOAD_SET_NEWPOSTMORTEM: {
      return {
        ...state,
        [action.payload.name]: action.payload.file,
      };
    }
    case Action.GET_NEWPOSTMORTEM_LIST: {
      return {
        ...state,
        PostMortemById: action.payload,
      };
    }
    case Action.GET_NEWPOSTMORTEM_BY_PROJECTID: {
      return {
        ...state,
        PostMortem: action.payload,
      };
    }
    case Action.GET_NEWPOSTMORTEM_PERCENTAGE: {
      return {
        ...state,
        PercentageLookup: action.payload,
      };
    }
    case Action.RESET_NEWPOSTMORTEM_FORM: {
      return {
        ...state,
        CustomerSurveryAttachements: [],
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default reducer;
