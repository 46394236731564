import React from "react";
import * as Action from "../../constants/ActionTypes";
import _ from "lodash";
import axios from "util/Api";
import * as Actions from "./index";
import IntlMessages from "util/IntlMessages";
import { batch } from "react-redux";
export const GetOpportunity = (values) => async (dispatch) => {
  return axios
    .get(
      `${Action.LOCALHOST}​api​/Opportunity​/GetOpportunityByProjectId​/{id}`
    )
    .then((Opportunity) => {
      return dispatch({
        type: Action.GET_OPPORTUNITY,
        payload: Opportunity.data.payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: Action.FETCH_ERROR,
        payload: errors,
      });
      console.log("Error****:", errors);
    });
};

export const saveOpportunity = (values) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${Action.LOCALHOST}api/Opportunity/CreateOpportunities`,
      values
    );

    if (res.data.IsSuccess) {
      dispatch(Actions.fetchSuccess("Opportunity saved Successfully"));
      dispatch(Actions.commonUpload("Opportunity", res.data.Payload));
      return { success: true, id: res.data.Payload };
    } else {
      dispatch(
        Actions.fetchError(res.data.ErrorMessage, "Opportunity saved Failed")
      );
      return { success: false, id: null };
    }
  } catch (err) {}
};

export const UpdateOpportunity = (values) => async (dispatch) => {
  try {
    return axios
      .post(`${Action.LOCALHOST}api/Opportunity/UpdateOpportunity`, values)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(Actions.fetchSuccess("Sucessfully Update"));
          dispatch(Actions.commonUpload("Opportunity", values.Id));
        } else {
          dispatch(Actions.fetchError(res.data.ErrorMessage, "Update Error!"));
        }
      })
      .catch((error) => {
        dispatch(Actions.fetchError(error.message, "Update Error!"));
      });
  } catch (err) {}
};

export const GetAllOpportunities = () => async (dispatch) => {
  try {
    return axios
      .get(`${Action.LOCALHOST}api/Opportunity/GetAllOpportunities`)
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_ALL_OPPORTUNITIES,
            payload: res.data.Payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      });
  } catch (err) {}
};

export const getOpportunitiesByLastWeek = () => async (dispatch) => {
  return axios
    .get(`${Action.LOCALHOST}api/Opportunity/GetOpportunitiesByLastWeek`)
    .then((res) => {
      if (res.data.Payload) {
        return dispatch({
          type: Action.GET_ALL_OPPORTUNITIES_BY_LAST_WEEK,
          payload: res.data.Payload,
        });
      } else {
        return dispatch({
          type: Action.SHOW_MESSAGE,
          payload: <IntlMessages id="Common.DataFetchError" />,
        });
      }
    });
};

export const getOppertunityByDepartment = () => async (dispatch) => {
  try {
    return axios
      .get(`${Action.LOCALHOST}api/Opportunity/GetAllOpportunities`)
      .then((res) => {
        if (res.data.Payload) {
          const department = _.groupBy(res.data.Payload, function (o) {
            return o.Resp_Dpt;
          });

          return dispatch({
            type: Action.GET_OPPORTUNITIES_BY_DEPT,
            payload: department,
          });
          console.log("department", department);
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      });
  } catch (err) {}
};

//04/04/2021

export const GetOpportunityAlerts = () => async (dispatch) => {
  try {
    return axios
      .get(`${Action.LOCALHOST}api/Opportunity/GetOpportunityAlerts`)
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_OPPORTUNITIES_ALERTS,
            payload: res.data.Payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      });
  } catch (err) {}
};

export const getOpportunitiesByLastTwodays = () => async (dispatch) => {
  try {
    return axios
      .get(`${Action.LOCALHOST}api/Opportunity/GetOpportunitiesByLastTwoDays`)
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_ALL_OPPORTUNITIES_BY_LAST_TWO_DAYS,
            payload: res.data.Payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      });
  } catch (err) {}
};

export const GetOpportunityById = (id) => async (dispatch) => {
  return axios
    .get(`${Action.LOCALHOST}api/Opportunity/GetOpportunityByProjectId/${id}`)
    .then((res) => {
      if (res.data.Payload) {
        dispatch({
          type: Action.SET_OPPORTUNITY_BY_ID,
          payload: res.data.Payload,
        });
        let attachement = res.data.Payload.lstAttachment;
        Promise.all([
          batch(() => {
            dispatch(Actions.handleGetUploads(attachement, "Opportunity"));
          }),
        ]);
      } else {
        return dispatch({
          type: Action.SHOW_MESSAGE,
          payload: <IntlMessages id="Common.DataFetchError" />,
        });
      }
    });
};

// let attachement = res.data.Payload.lstAttachment;
// Promise.all([
//   batch(() => {
//     dispatch(Action.handleGetUploads(attachement, "Project"));
//   }),
// ]);
// }
// export const deleteOpportunity=(id,current,pazesize) => async  (dispatch)=>{

//   }

export const deleteOpportunity =
  (id, current, inchargeId) => async (dispatch) => {
    return axios
      .post(`${Action.LOCALHOST}api/Opportunity/DeleteOpportunityById/${id}`)
      .then(() => {
        if (current == "last_seven_days") {
          dispatch(Actions.getOpportunitiesByLastWeek());
        } else if (current == "last_two_days") {
          dispatch(Actions.getOpportunitiesByLastTwodays());
        } else if (current == "my_opportunities") {
          dispatch(Actions.MyOpportunities(inchargeId));
        } else if (current == "opportunities_By_Dept") {
          dispatch(Actions.getOppertunityByDepartment());
        } else if (current == "alarm") {
          dispatch(Actions.GetOpportunityAlerts());
        } else {
          dispatch(Actions.GetAllOpportunities());
        }
      });
  };
export const resetnewForm = () => {
  return {
    type: Action.RESET_OPPORTUNITY,
  };
};

export const OrderDocumentsforOpportunity = (id) => async (dispatch) => {
  try {
    return axios
      .get(
        `${Action.LOCALHOST}api/Opportunity/OrderDocumentsforOpportunity/${id}`
      )
      .then((res) => {
        if (res.data.payload) {
          return dispatch({
            type: Action.GET_OPPORTUNITIES_ORDER_DOCUMENT,
            payload: res.data.payload,
          });
        }
      });
  } catch (err) {}
};

export const UpdateDecision =
  (OpportunityId, decision, decision_reason) => async (dispatch) => {};

// export const UpdateDecision= (values) => async (dispatch) => {
//   try {
//     return axios
//       .put(`${Action.LOCALHOST}api/Opportunity/UpdateDecision`, values)
//       .then((res) => {
//         if (res.data.Payload) {
//           dispatch(Actions.fetchSuccess("Sucessfully Update"));
//         } else {
//           dispatch(Actions.fetchError(res.data.ErrorMessage, "Update Error!"));
//         }
//       })
//       .catch((error) => {
//         dispatch(Actions.fetchError(error.message, "Update Error!"));
//       });
//   } catch (err) {
//   }
// };

export const MyOpportunities = (inchargeId) => async (dispatch) => {
  return axios
    .get(`${Action.LOCALHOST}api/Opportunity/GetMyOpportunities/${inchargeId}`)
    .then((res) => {
      if (res.data.Payload) {
        dispatch({
          type: Action.MY_OPPORTUNITIES,
          payload: res.data.Payload,
        });
      } else {
        return dispatch({
          type: Action.SHOW_MESSAGE,
          payload: <IntlMessages id="Common.DataFetchError" />,
        });
      }
    });
};
