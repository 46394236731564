import * as Action from "../../constants/ActionTypes";
const inistState = {
  Project: {
    count: 0,
    success: 0,
    failure: 0,
  },
  RiskAnalysis: {
    count: 0,
    success: 0,
    failure: 0,
  },
  Planification: {
    count: 0,
    success: 0,
    failure: 0,
  },
  ProjectPhase: {
    count: 0,
    success: 0,
    failure: 0,
  },
  PhaseOffre: {
    count: 0,
    success: 0,
    failure: 0,
  },
  Avenant: {
    count: 0,
    success: 0,
    failure: 0,
  },
  PlannificationSurveillance: {
    count: 0,
    success: 0,
    failure: 0,
  },
  Review: {
    count: 0,
    success: 0,
    failure: 0,
  },
  Opportunity: {
    count: 0,
    success: 0,
    failure: 0,
  },
  PlannificationConception: {
    count: 0,
    success: 0,
    failure: 0,
  },
  NewPostMortem: {
    count: 0,
    success: 0,
    failure: 0,
  },
};
const reducer = (state = inistState, action) => {
  switch (action.type) {
    case Action.SET_IDENTIFIER_BY_FORM_NAME: {
      const { name, form, payload } = action;
      let Form = state[form];
      let Identifier = Form[name];
      console.log(Identifier, name, form);
      if (Identifier === null || Identifier === undefined) Identifier = [];
      Identifier.push(payload);
      return {
        ...state,
        [form]: {
          ...state[form],
          [name]: Identifier,
          count: ++state[form].count,
        },
      };
    }
    case Action.UPDATE_IDENTIFIER_BY_FORM_NAME: {
      const { name, form, payload } = action;
      return {
        ...state,
        [form]: {
          ...state[form],
          [name]: payload,
        },
      };
    }
    case Action.RESET_COUNTER_BY_FORM: {
      const { form } = action;
      return {
        ...state,
        [form]: {
          ...state[form],
          count: 0,
          success: 0,
          failure: 0,
        },
      };
    }
    case Action.RESET_WHOLE_STATE_UPLOADS: {
      const { form } = action;
      if (form === "all") {
        return {
          Project: {
            count: 0,
            success: 0,
            failure: 0,
          },
          RiskAnalysis: {
            count: 0,
            success: 0,
            failure: 0,
          },
          Planification: {
            count: 0,
            success: 0,
            failure: 0,
          },
          ProjectPhase: {
            count: 0,
            success: 0,
            failure: 0,
          },
          PhaseOffre: {
            count: 0,
            success: 0,
            failure: 0,
          },
          Avenant: {
            count: 0,
            success: 0,
            failure: 0,
          },
          PlannificationSurveillance: {
            count: 0,
            success: 0,
            failure: 0,
          },
          Review: {
            count: 0,
            success: 0,
            failure: 0,
          },
          Opportunity: {
            count: 0,
            success: 0,
            failure: 0,
          },
          PlannificationConception: {
            count: 0,
            success: 0,
            failure: 0,
          },
          NewPostMortem: {
            count: 0,
            success: 0,
            failure: 0,
          },
        };
      } else {
        return {
          ...state,
          [form]: {
            count: 0,
            success: 0,
            failure: 0,
          },
        };
      }
    }
    case Action.REMOVE_IDENTIFIER_BY_FORM_NAME: {
      const { name, form, payload } = action;
      let Form = state[form];
      let Identifier = Form[name];
      Identifier.forEach((element) => {
        if (element.uid === payload.uid) {
          element.isDeleted = true;
        }
      });
      // let arr = Identifier.filter((val) => val.uid !== payload.uid);
      return {
        ...state,
        [form]: {
          ...state[form],
          [name]: Identifier,
          count: --state[form].count,
        },
      };
    }
    case Action.HANDLE_COUNTER_BY_NAME: {
      const { name, form, counter } = action;
      let total = state[form][name] + counter;
      return {
        ...state,
        [form]: {
          ...state[form],
          [name]: total,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
