import * as Action from "../../constants/ActionTypes";
import _ from "lodash";
import axios from "util/Api";
import * as Actions from "./index";
import React from "react";
import IntlMessages from "util/IntlMessages";

export const SavePost = (data) => async (dispatch) => {
  return axios
    .post(`${Action.LOCALHOST}api/PostMortem/Create`, data)
    .then((res) => {
      dispatch(Actions.fetchSuccess(<IntlMessages id="PostMortem.Create" />));
      return res;
    })
    .catch(() => {
      dispatch(
        Actions.fetchError(
          "Saved Faild!",
          <IntlMessages id="PostMortem.NotCreate" />
        )
      );
    });
};

export const UpdatePost = (data) => async (dispatch) => {
  return axios
    .put(`${Action.LOCALHOST}api/PostMortem/Update`, data)
    .then((res) => {
      dispatch(Actions.fetchSuccess(<IntlMessages id="PostMortem.Update" />));
    })
    .catch(() => {
      dispatch(
        Actions.fetchError(
          "Updated Faild!",
          <IntlMessages id="PostMortem.NotCreate" />
        )
      );
    });
};

// test

export const GetPostMortemById = (id) => (dispatch) => {
  return axios
    .get(`${Action.LOCALHOST}api/PostMortem/${id}`)
    .then((res) => {
      return dispatch({
        type: Action.GET_POSTMORTEM_LIST,
        payload: res.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: Action.FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};

export const GetPostMortemByProjectId = (projectId) => (dispatch) => {
  return axios
    .get(
      `${Action.LOCALHOST}api/PostMortem/GetPostMortemByProjectId/${projectId}`
    )
    .then((res) => {
      return dispatch({
        type: Action.GET_POSTMORTEM_BY_PROJECTID,
        payload: res.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: Action.FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};

export const GetManagerHistoryByProjectId = (projectId) => (dispatch) => {
  return axios
    .get(
      `${Action.LOCALHOST}api/History/GetManagersHistoryForProject/${projectId}`
    )
    .then((res) => {
      return dispatch({
        type: Action.GET_MANAGER_HISTORY_BY_PROJECTID,
        payload: res.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: Action.FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};

export const callCreateProjectSheetExe = (projectId) => {
  return (dispatch, state) => {
    return axios
      .get(
        `${Action.LOCALHOST}api/PostMortem/CallCreateProjectSheetExe/${projectId}`
      )
      .then((res) => {
        if (res.data.Payload) {
          return true;
        } else {
          dispatch(Actions.fetchError(<IntlMessages id="Common.Failed" />));
          return false;
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
// test cose
