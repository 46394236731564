import * as Action from "../../constants/ActionTypes";
import * as Actions from "./index";
import axios from "util/Api";
import _ from "lodash";
import { batch } from "react-redux";
import React from "react";
import IntlMessages from "util/IntlMessages";
export const getRiskAnalysisById = (id) => {
  return (dispatch) => {
    axios
      .get(`${Action.LOCALHOST}api/RiskAnalysis/Project/${id}`)
      .then((res) => {
        if (res.data.IsSuccess) {
          return dispatch({
            type: Action.GET_RISK_ANALYSIS_BY_ID,
            payload: res.data.Payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const savaRiskAnalysis = (data) => {
  return (dispatch, state) => {
    const { projectGotById } = state().createProject;
    const { table } = state().RiskAnalysis;
    let obj = {
      ...data,
      IdProjet: projectGotById.IdProjet,
      // supplierIdentification: table,
    };
    return axios
      .post(`${Action.LOCALHOST}api/RiskAnalysis/Create`, obj)
      .then((res) => {
        if (res.data.IsSuccess) {
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="RiskAnalysis.Save" />)
          );
          Promise.all([
            dispatch(
              Actions.commonUpload("RiskAnalysis", parseInt(res.data.Payload))
            ),
            dispatch(Actions.GetHeaderByProjectId(obj.IdProjet)),
          ]).then(() => {
            dispatch(getRiskAnalysisById(projectGotById.IdProjet));
          });
          return true;
        } else {
          dispatch(
            Actions.fetchError(
              "Saved Error",
              <IntlMessages id="RiskAnalysis.Fail" />
            )
          );
          return false;
        }
      })
      .catch((err) => {
        dispatch(
          Actions.fetchError(
            err.message,
            <IntlMessages id="RiskAnalysis.Fail" />
          )
        );
        return false;
      });
  };
};
export const updateRiskAnalysis = (data) => {
  return (dispatch, state) => {
    const { projectGotById } = state().createProject;
    const { table } = state().RiskAnalysis;
    let obj = {
      ...data,
      IdProjet: projectGotById.IdProjet,
      // SupplierIdentification: table,
    };
    return axios
      .put(`${Action.LOCALHOST}api/RiskAnalysis/Update`, obj)
      .then((res) => {
        if (res.data.IsSuccess) {
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="RiskAnalysis.Update" />)
          );
          Promise.all([
            dispatch(
              Actions.commonUpload("RiskAnalysis", parseInt(res.data.Payload))
            ),
            dispatch(Actions.GetHeaderByProjectId(obj.IdProjet)),
          ]).then(() => {
            dispatch(getRiskAnalysisById(projectGotById.IdProjet));
          });
          return true;
        } else {
          dispatch(
            Actions.fetchError(
              "Saved Error",
              <IntlMessages id="RiskAnalysis.Fail" />
            )
          );
          return false;
        }
      })
      .catch((err) => {
        dispatch(
          Actions.fetchError(
            err.message,
            <IntlMessages id="RiskAnalysis.Fail" />
          )
        );
        return false;
      });
  };
};
export const deleteRiskAnalysisById = (id) => {
  return (dispatch, state) => {
    const { projectGotById } = state().createProject;
    return axios
      .delete(`${Action.LOCALHOST}api/RiskAnalysis/${id}`)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(getRiskAnalysisById(projectGotById.IdProjet));
          dispatch(Actions.GetHeaderByProjectId(projectGotById.IdProjet));
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.SuccessDelete" />)
          );
          return true;
        } else {
          dispatch(
            Actions.fetchError(
              "Failed Deleted",
              <IntlMessages id="Common.FailDelete" />
            )
          );
          return false;
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Common.FailDelete" />)
        );
        return false;
      });
  };
};
export const updateSpecificRiskTableData = (payload) => {
  return (dispatch, getState) => {
    const { table } = getState().RiskAnalysis;
    const temp = table.filter((val) => val.Active === true);
    let temp2 = table.filter((val) => val.Active === false);
    temp[payload.index] = { ...temp[payload.index], ...payload };
    dispatch(UpdateInnerFormRiskAnalysis([...temp, ...temp2]));
  };
};
export const deleteSpecificRiskTableData = (payload) => {
  return (dispatch, getState) => {
    const { table } = getState().RiskAnalysis;
    let temp = table.filter((val) => val.Active === true);
    let temp2 = table.filter((val) => val.Active === false);
    if (temp[payload.index].IdSubcontractorSupplier !== 0) {
      console.log(temp[payload.index]);
      temp[payload.index] = {
        ...temp[payload.index],
        ...payload,
        Active: false,
      };
    } else delete temp[payload.index];
    temp = _.compact(temp);
    console.log("TEMP", [...temp, ...temp2]);
    dispatch(UpdateInnerFormRiskAnalysis([...temp, ...temp2]));
  };
};
export const addRiskAnalysisTableData = (payload) => {
  return {
    type: Action.ADD_RISK_TABLE_DATA,
    payload,
  };
};
export const editInnerRiskAnalysisTableData = (id) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/RiskAnalysis/${id}`)
      .then((res) => {
        if (res.data.Payload) {
          let attachement = res.data.Payload.ListAttachment;
          batch(() => {
            dispatch(Actions.resetWholeFormInDragger("RiskAnalysis"));
            dispatch(Actions.handleGetUploads(attachement, "RiskAnalysis"));
          });
          // let arr = res.data.Payload.SupplierIdentification.map((v, k) => {
          //   return {
          //     ...v,
          //     index: k,
          //   };
          // });
          Promise.all([
            dispatch({
              type: Action.RISK_ANALYSIS_TABLE_EDIT_DATA,
              payload: res.data.Payload,
            }),
            // dispatch(UpdateInnerFormRiskAnalysis(arr)),
          ]);
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const resetRiskAnalysisForm = () => {
  return {
    type: Action.RISK_ANALYSIS_RESET_FORM_DATA,
  };
};
export const UpdateInnerFormRiskAnalysis = (payload) => {
  return {
    type: Action.UPDATE_RISK_ANALYSIS_INNER_TABLE,
    payload,
  };
};
export const editInnerTableRiskAnalysis = (payload) => {
  return {
    type: Action.RISK_ANALYSIS_EDIT_INNER_TABLE,
    payload,
  };
};
