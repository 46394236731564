import decode from "jwt-decode";
export const TOKEN_NAME = "TOKEN";
export const USER_DATA = "user";
export const SET_PROFILE = "profilePhoto";
export function setToken(token) {
  localStorage.setItem(TOKEN_NAME, token);
}
export function setTokens(token) {
  localStorage.setItem("token", token);
}
export function getToken() {
  return localStorage.getItem(TOKEN_NAME);
}
export function SetImage(img) {
  localStorage.setItem(SET_PROFILE, img);
}
export function getImage() {
  return localStorage.getItem(SET_PROFILE);
}
export function getUser() {
  if (isLoggedin()) return decode(localStorage.getItem(TOKEN_NAME));
  else return null;
}
export function setUserDetail(data) {
  localStorage.setItem(USER_DATA, JSON.stringify(data));
}
export function getUserDetail() {
  return JSON.parse(localStorage.getItem(USER_DATA));
}
export function hasRole(role) {
  let user = getUser();
  if (user) {
    return user.role.indexOf(role) > -1;
  }
  return false;
}
export function logout() {
  localStorage.clear();
}
export function isTokenExpired(token) {
  if (!token) token = getToken();
  if (!token) return true;
  const date = getTokenExpirationDate(token);
  if (date === undefined) return false;
  return !(date.valueOf() > new Date().valueOf());
}
export function isLoggedin() {
  if (isTokenExpired()) return false;
  return true;
}
export function getTokenExpirationDate(token) {
  const decoded = decode(token);
  if (decoded.iat === undefined) return null;
  const date = new Date(0);
  date.setUTCSeconds(decoded.exp);
  return date;
}

export function isNormalUser() {
  return userIsInAdGroup("FOCUSGP-ING-TECH");
}

export function isAccountingUser() {
  return userIsInAdGroup("FOCUSGP-COMPT");
}

export function isProjectManager() {
  return userIsInAdGroup("FOCUSGP-CP,FOCUSGP-ADJ");
}

export function isDirector() {
  return userIsInAdGroup("FOCUSGP-VP,FOCUSGP-DIR");
}

export function isAdmin() {
  return userIsInAdGroup("FOCUSGP-ADMIN");
}

export function canDelete() {
  return userIsInAdGroup("FOCUSGP-ADMIN,FOCUSGP-COMPT");
}
export function isBiddingUser() {
  return userIsInAdGroup("FOCUSGP-BID");
}

export function canSaveOrUpdate() {
  return userIsInAdGroup(
    "FOCUSGP-ADMIN,FOCUSGP-ING-TECH,FOCUSGP-CP,FOCUSGP-VP,FOCUSGP-DIR,FOCUSGP-ADJ,FOCUSGP-COMPT"
  );
}

export function canCreateMandate() {
  return userIsInAdGroup(
    "FOCUSGP-ADMIN,FOCUSGP-CP,FOCUSGP-VP,FOCUSGP-DIR,FOCUSGP-ADJ"
  );
}

export function canAddNew() {
  return userIsInAdGroup(
    "FOCUSGP-ADMIN,FOCUSGP-ING-TECH,FOCUSGP-CP,FOCUSGP-VP,FOCUSGP-DIR,FOCUSGP-ADJ,FOCUSGP-COMPT"
  );
}

export function canSeeApproveTab() {
  return isAdmin() || userIsInAdGroup("FOCUSGP-VP,FOCUSGP-DIR");
}
export function canSeeDashboard() {
  return (
    isAdmin() ||
    userIsInAdGroup(
      "FOCUSGP-CP,FOCUSGP-VP,FOCUSGP-DIR,FOCUSGP-ADJ,FOCUSGP-CS,FOCUSGP-COMPT"
    )
  );
}
export function canSeeMyApproveTab() {
  return isAdmin() || userIsInAdGroup("FOCUSGP-CP,FOCUSGP-VP,FOCUSGP-DIR");
}
export function canApproveInApproveTab() {
  return isProjectManager() || isDirector() || isAdmin();
}

export function canSeeAccountingTab() {
  return isAccountingUser() || isAdmin();
}

export function canEditBiddingFrorm() {
  return isBiddingUser() || isAdmin();
}

export function userIsInAdGroup(groupName) {
  let userRoles = JSON.parse(localStorage.getItem("userroles"));
  let roletoCheck = groupName;
  let result = false;
  if (userRoles) {
    userRoles.forEach((role) => {
      if (roletoCheck.toLowerCase().indexOf(role.toLowerCase()) !== -1) {
        result = true;
      }
    });
  }
  return result;
}
