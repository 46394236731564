import React from "react";
import * as Action from "../../constants/ActionTypes";
import _ from "lodash";
import axios from "util/Api";
import IntlMessages from "util/IntlMessages";
export const getProjectShort = () => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/Lookup/ProjectShort`)
      .then((res) => {
        console.log("Project Short List", res.data);
        if (res.data.Payload) {
          const payload = _.groupBy(res.data.Payload, function (o) {
            // return o.Department;
            return "project";
          });
          return dispatch({
            type: Action.GET_PROJECT_SHORT,
            payload: payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const getAllProjectNumbers = (searchKey) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/Lookup/GetAllProjectNumbers/${searchKey}`)
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_ALL_PROJECT_NUMBERS,
            payload: res.data.Payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
