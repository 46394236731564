import * as Action from "../../constants/ActionTypes";
import axios from "util/Api";
import * as Actions from "./index";
import { batch } from "react-redux";
import React from "react";
import IntlMessages from "util/IntlMessages";
export const getSurveillanceByProjectId = (ID) => {
  return (dispatch) => {
    console.log("get all planification sur");
    return axios
      .get(`${Action.LOCALHOST}api/PlannificationSurveillance/Project/${ID}`)
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.SET_SURVEILLANCE_TABLE_DATA,
            payload: res.data.Payload,
          });
        } else throw res.data;
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const deleteSurveillance = (id, projectId) => {
  return (dispatch) => {
    console.log("DeleteAvenant with ID", id);
    return axios
      .delete(`${Action.LOCALHOST}api/PlannificationSurveillance/${id}`)
      .then((res) => {
        if (res.data.IsSuccess) {
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.SuccessDelete" />)
          );
          return dispatch(
            getSurveillanceByProjectId(projectId !== undefined ? projectId : 0)
          );
        } else throw res.data;
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        console.log(err);
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Common.FailDelete" />)
        );
      });
  };
};

export const saveSurveillance = (val) => {
  return (dispatch, getState) => {
    console.log(JSON.stringify(val));
    const value = getState().Surveillance;
    console.log(JSON.stringify(val));
    return axios
      .post(`${Action.LOCALHOST}api/PlannificationSurveillance/Create`, val)
      .then((res) => {
        if (res.data.IsSuccess) {
          console.log(res);
          batch(() => {
            dispatch(
              getSurveillanceByProjectId(
                val.IdProject !== undefined ? val.IdProject : 0
              )
            );
            dispatch(Actions.GetHeaderByProjectId(val.IdProject));
            dispatch(
              Actions.commonUpload(
                "PlannificationSurveillance",
                res.data.Payload
              )
            );
          });

          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.DataSuccess" />)
          );
          return true;
        } else throw res.data;
      })
      .catch((err) => {
        const errors = err.StatusDesc || err.title;
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Common.DataFail" />)
        );
        return false;
      });
  };
};

export const getSurveillanceByID = (ID) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/PlannificationSurveillance/${ID}`)
      .then((res) => {
        if (res.data.IsSuccess) {
          return batch(() => {
            dispatch({
              type: Action.GET_SURVEILLANCE_BY_ID,
              payload: res.data.Payload,
            });
            let attachement = res.data.Payload.lstAttachment;
            dispatch(
              Actions.handleGetUploads(
                attachement,
                "PlannificationSurveillance"
              )
            );
          });
        } else throw res.data;
      })
      .catch((err) => {
        const errors = err.StatusDesc || err.title;
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Planification.Fetch" />)
        );
        return false;
      });
  };
};

export const updateSurveillance = (val) => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;
    const authdata = getState().auth;
    return axios
      .put(`${Action.LOCALHOST}api/PlannificationSurveillance/Update`, val)
      .then((res) => {
        console.log(res);
        if (res.data.IsSuccess) {
          if (
            val?.ApprovalStatus === 2 &&
            projectGotById?.EmployeIdChargeProjet ===
              authdata?.authUser?.employeeid
          ) {
            dispatch(
              Actions.changeStatus(
                val?.IdPlannification,
                2,
                "PS",
                null,
                null,
                true
              )
            ).then(() => {
              dispatch(
                getSurveillanceByProjectId(
                  val.IdProject !== undefined ? val.IdProject : 0
                )
              );
            });
          } else {
            dispatch(
              getSurveillanceByProjectId(
                val.IdProject !== undefined ? val.IdProject : 0
              )
            );
          }
          dispatch(
            Actions.commonUpload(
              "PlannificationSurveillance",
              val.IdPlannification
            )
          );
          dispatch(Actions.fetchSuccess(<IntlMessages id="Common.Update" />));
          return true;
        } else throw res.data;
      })
      .catch((err) => {
        const errors = err.StatusDesc || err.title;
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Common.UpdateError" />)
        );
        return false;
      });
  };
};
export const dynamicAttachementPlanification = (name, value) => {
  return {
    type: Action.PLANIFICATION_KEY_IDENTIFIER,
    payload: value,
    name,
  };
};

export const updateIdentifierByIdSurvellance = (name, file) => {
  return {
    type: Action.UPDATE_IDENTIFIER_BY_KEY_SURVEILLANCE,
    name,
    payload: file,
  };
};

export const resetSurveillanceForm = () => {
  return {
    type: Action.RESET_SURVEILLANCE_FORM,
  };
};
