const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "Language.English",
    icon: "us",
  },
  {
    languageId: "french",
    locale: "fr",
    name: "Language.French",
    icon: "fr",
  },
];
export default languageData;
