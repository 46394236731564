import React from "react";
import * as Action from "../../constants/ActionTypes";
import * as Actions from "./index";
import axios from "util/Api";
import _ from "lodash";
import { toast } from "react-toastify";
import { Progress } from "antd";
import IntlMessages from "util/IntlMessages";
export const addIdentifierCommon = (form, name, file) => {
  return {
    type: Action.SET_IDENTIFIER_BY_FORM_NAME,
    form,
    name,
    payload: file,
  };
};
export const updateIdentifierCommon = (form, name, payload) => {
  return {
    type: Action.UPDATE_IDENTIFIER_BY_FORM_NAME,
    name,
    form,
    payload,
  };
};
export const removeIdentifierCommon = (form, name, payload) => {
  return {
    type: Action.REMOVE_IDENTIFIER_BY_FORM_NAME,
    name,
    form,
    payload,
  };
};
export const handleCounterInUpdate = (form, counter, name) => {
  return {
    type: Action.HANDLE_COUNTER_BY_NAME,
    form,
    counter,
    name,
  };
};
export const commonUpload = (form, id) => {
  return (dispatch, getState) => {
    const state = getState().Uploads;
    const data = state[form];
    let check = true;
    _.mapValues(data, (value, key) => {
      if (key !== "count" && key !== "success" && key !== "failure") {
        if (value?.length > 0) {
          value.map((v) => {
            if (v.url === null) {
              check = false;
            }
          });
        }
      }
    });

    if (!check) {
      toast(
        <div className="gx-d-flex Notification-Container gx-align-items-center gx-justify-content-center">
          <div className="Progress-Container">
            <Progress type="circle" className="gx-m-0" percent={0} width={50} />
          </div>
          <div className="Notification-Content">
            <h4>{<IntlMessages id="Common.Uploading" />}</h4>
            <div className="row">
              <span>{<IntlMessages id="Common.Done" />}</span>
              <span>:</span>
              <span>0</span>
            </div>
            <div className="row">
              <span>{<IntlMessages id="Common.Failed" />}</span>
              <span>:</span>
              <span>0</span>
            </div>
            <div className="row">
              <span>{<IntlMessages id="Common.Total" />}</span>
              <span>:</span>
              <span>{data.count}</span>
            </div>
          </div>
        </div>,
        {
          progress: 0,
          position: "bottom-left",
          hideProgressBar: true,
          toastId: form,
          autoClose: false,

          onClose: (val) => {
            if (form === "Project") {
              dispatch(Actions.GetProjectById(id));
            } else {
              dispatch(resetWholeFormInDragger(form));
            }
          },
        }
      );
    }
    return _.mapValues(data, (value, key) => {
      if (key !== "count" && key !== "success" && key !== "failure") {
        const filesToDelete = [];
        if (value?.length > 0) {
          var newFilesList = value.filter(function (obj) {
            return obj.isDeleted === false;
          });
          let count = newFilesList.length;
          value.map((v) => {
            const formData = new FormData();
            if (v.url === null) {
              formData.append("files", v.file);
            }
            if (v.isDeleted === true) {
              filesToDelete.push(v.name);
            }
            
            if (formData.get("files")) {
              return axios
                .post(
                  `${Action.LOCALHOST}api/Attachment/${form}/Create/${id}/${key}`,
                  formData
                )
                .then((res) => {
                  if (res.data.IsSuccess) {
                    dispatch(handleCounterInUpdate(form, 1, "success"));
                    return true;
                  } else {
                    dispatch(handleCounterInUpdate(form, 1, "failure"));
                    return false;
                  }
                })
                .catch((Error) => {
                  dispatch(handleCounterInUpdate(form, 1, "failure"));
                  return false;
                });
            }
          });

          if (filesToDelete.length > 0) {
            return axios
              .post(
                `${Action.LOCALHOST}api/Attachment/${form}/Delete/${id}/${key}`,
                filesToDelete
              )
              .then((res) => {
                dispatch(handleCounterInUpdate(form, count, "success"));
                return true;
                // if (res.data.IsSuccess) {
                //   dispatch(handleCounterInUpdate(form, count, "success"));
                //   return true;
                // } else {
                //   dispatch(handleCounterInUpdate(form, count, "failure"));
                //   return false;
                // }
              })
              .catch((Error) => {
                dispatch(handleCounterInUpdate(form, count, "failure"));
                return false;
              });
          }
        }
      }
    });
  };
};
export const handleGetUploads = (attachement, name) => {
  return (dispatch) => {
    // dispatch(resetWholeFormInDragger(name));
    if (attachement) {
      _.mapValues(attachement, (value, key) => {
        dispatch(updateIdentifierCommon(name, key, value));
      });
    }
  };
};
export const resetCounterInDragger = (form) => {
  return {
    type: Action.RESET_COUNTER_BY_FORM,
    form,
  };
};
export const resetWholeFormInDragger = (form) => {
  return {
    type: Action.RESET_WHOLE_STATE_UPLOADS,
    form: form,
  };
};
