import * as Action from "../../constants/ActionTypes";
const initState = {
  ProjectShort: [],
  ProjectNumbers: [],
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.GET_PROJECT_SHORT: {
      return {
        ...state,
        ProjectShort: action.payload.project,
      };
    }
    case Action.GET_ALL_PROJECT_NUMBERS: {
      return {
        ...state,
        ProjectNumbers: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default reducer;
