import * as Action from "../../constants/ActionTypes";
const initState = {
  TableData: [],
  TeamProjectAttachment: [],
  IdentificationAttachment: [],
  OHSAttachment: [],
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.SET_SURVEILLANCE_TABLE_DATA: {
      return {
        ...state,
        TableData: action.payload,
      };
    }
    case Action.GET_SURVEILLANCE_BY_ID: {
      return {
        ...state,
        SurvData: action.payload,
      };
    }
    case Action.PLANIFICATION_KEY_IDENTIFIER: {
      let arr = state[action.name];
      if (arr === null || arr === undefined) {
        arr = [];
      }
      arr.push(action.payload);
      return {
        ...state,
        [action.name]: arr,
      };
    }
    case Action.UPDATE_IDENTIFIER_BY_KEY_SURVEILLANCE: {
      return {
        ...state,
        [action.name]: action.payload,
      };
    }
    case Action.RESET_SURVEILLANCE_FORM: {
      return {
        ...state,
        TeamProjectAttachment: [],
        IdentificationAttachment: [],
        OHSAttachment: [],
        SurvData: [],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
