import * as Action from "../../constants/ActionTypes";
import axios from "util/Api";
import React from "react";
import IntlMessages from "util/IntlMessages";

export const getListByCharge = (
  page,
  size,
  projectNumber = "",
  daveOpening = "",
  daveClosing = "",
  responsible = "",
  searchKey = "",
  ClientNom = ""
) => {
  return (dispatch) => {
    return axios
      .post(`${Action.LOCALHOST}api/Project/GetAllListByCharge`, {
        pageNumber: page || 1,
        pageSize: size || 10,
        projectNumber: projectNumber,
        daveOpening: daveOpening,
        daveClosing: daveClosing,
        responsible: responsible,
        SearchKey: searchKey,
        ClientNom: ClientNom,
      })
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_LIST_BY_CHARGE,
            payload: res.data,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const getListByDepartment = (
  ChargeId,
  page,
  size,
  projectNumber = "",
  daveOpening = "",
  daveClosing = "",
  responsible = "",
  SearchKey = "",
  ClientNom = ""
) => {
  return (dispatch) => {
    return axios
      .post(`${Action.LOCALHOST}api/Project/GetAllListByDepartment`, {
        pageNumber: page || 1,
        pageSize: size || 10,
        projectNumber: projectNumber,
        daveOpening: daveOpening,
        daveClosing: daveClosing,
        responsible: responsible,
        SearchKey: SearchKey,
        ClientNom: ClientNom,
        SearchValue: ChargeId,
      })
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_LIST_BY_DEPARTMENT,
            payload: res.data,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const GetDepartmentsProposal = (
  page,
  size,
  projectNumber = "",
  daveOpening = "",
  daveClosing = "",
  responsible = "",
  SearchKey = "",
  ClientNom = ""
) => {
  return (dispatch) => {
    return axios
      .post(`${Action.LOCALHOST}api/Project/GetDepartmentsProposal`, {
        pageNumber: page || 1,
        pageSize: size || 50,
        projectNumber: projectNumber,
        daveOpening: daveOpening,
        daveClosing: daveClosing,
        responsible: responsible,
        SearchKey: SearchKey,
        ClientNom: ClientNom,
      })
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_LIST_BY_DEPARTMENT,
            payload: res.data,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const runtimeKey = (
  departmentId,
  key,
  page,
  projectNumber = "",
  daveOpening = "",
  daveClosing = "",
  responsible = "",
  SearchKey = "",
  ClientNom = ""
) => {
  return (dispatch) => {
    return axios
      .post(`${Action.LOCALHOST}api/Project/GetListByChargeDetails`, {
        pageNumber: page,
        pageSize: 50,
        searchValue: key,
        DepartmentId: departmentId,
        projectNumber: projectNumber,
        daveOpening: daveOpening,
        daveClosing: daveClosing,
        responsible: responsible,
        SearchKey: SearchKey,
        ClientNom: ClientNom,
      })
      .then((res) => {
        const value = key.split(" ")[0] + departmentId;
        if (res.data.Payload) {
          return dispatch({
            type: Action.RUN_TIME_KEY_LIST,
            payload: {
              data: res.data.Payload,
              key: value,
              page: res.data.TotalRecordCount,
            },
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        console.log(err);
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const ResetManagers = () => {
  return {
    type: Action.ResetManagers,
  };
};
