import * as Action from "../../constants/ActionTypes";
import moment from "moment";
const initState = {
  reviewFormTable: [],
  ReviewAttachment: null,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.SET_REVIEWFORM_TABLE_DATA: {
      return {
        ...state,
        reviewFormTable: action.payload.RevueDeProjetList,
      };
    }
    case Action.GET_REVIEWFORM_BY_ID: {
      let review = action.payload;
      let obj = {
        Id: review.RevueDeProjet.Id,
        NoProjet: review.RevueDeProjet.IdProjet.toString(),
        progress: review.RevueDeProjet.Progress,
        remarkgen: review.RevueDeProjet.RemarksGeneral,
        ApprovalStatus: review.RevueDeProjet.ApprovalStatus,
        reviewNumber: review.RevueDeProjet.ReviewNumber,
        // AttachmentPath: review.RevueDeProjet.AttachmentPath,
      };
      review.Participants.forEach((part, index) => {
        obj["participantId" + (index + 1)] = part.Id;
        obj["participant" + (index + 1)] = part.ParticipantEmployeeId;
        obj["discipline" + (index + 1)] = part.Discipline;
      });
      review.Elements.forEach((ele, index) => {
        obj["elementId" + (index + 1)] = ele.Id;
        obj["list" + (index + 1)] = ele.ReviewStatus;
        obj["remark" + (index + 1)] = ele.Remarks;
      });

      return {
        ...state,
        ReviewData: obj,
      };
    }
    case Action.RESET_REVIEWFORM_FORM: {
      return {
        ...state,
        ReviewData: null,
        ReviewAttachment: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default reducer;
