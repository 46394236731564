import { routerActions } from "connected-react-router";
import * as Action from "../../constants/ActionTypes";
const initState = {
  TableData: [],
  table: [],
  edit: null,
  innerEdit: null,
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.HISTORY_APPROVE: {
      return {
        ...state,
        HistoryApprove: action.payload,
      };
    }
    case Action.TRANSACTIONS: {
      return {
        ...state,
        Transactions: action.payload,
      };
    }
    case Action.MODIFIEDFIELDS: {
      return {
        ...state,
        ModifiedFields: action.payload,
      };
    }
    case Action.RESET_MODIFIEDFIELDS: {
      return {
        ...state,
        ModifiedFields: [],
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
