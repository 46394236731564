import React from "react";
import * as Action from "../../constants/ActionTypes";
import * as Actions from "./index";
import axios from "util/Api";
import _ from "lodash";
import { batch } from "react-redux";
import IntlMessages from "util/IntlMessages";

export const getBiddingMasterData = () => async (dispatch) => {
  let locale = JSON.parse(localStorage.getItem("selectedlanguage"));
  return axios
    .get(`${Action.LOCALHOST}api/Bidding/masterdata?language=${locale?.locale}`)
    .then((masterData) => {
      const { BiddingVP } = masterData.data.Payload;

      return dispatch({
        type: Action.GET_BIDDING_MASTERDATA,
        payload: masterData.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: Action.FETCH_ERROR,
        payload: errors,
      });
    });
};

export const getBiddingQuoteByProjectId = () => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;
    if (projectGotById) {
      return axios
        .get(
          `${Action.LOCALHOST}api/BiddingQuote/Project/${projectGotById.IdProjet}`
        )
        .then((res) => {
          if (res.data.Payload) {
            return batch(() => {
              dispatch({
                type: Action.SET_BIDDING_QUOTE_TABLE_DATA,
                payload: res.data.Payload,
              });
            });
          } else {
            dispatch({
              type: Action.SHOW_MESSAGE,
              payload: <IntlMessages id="Common.DataFetchError" />,
            });
          }
        })
        .catch((err) => {
          const errors = err.StatusDesc;
          dispatch({
            type: Action.FETCH_ERROR,
            payload: errors,
          });
        });
    }
  };
};

export const SaveBiddingForm = (values) => async (dispatch) => {
  try {
    return axios
      .post(`${Action.LOCALHOST}api/Bidding/submit`, values)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(Actions.fetchSuccess("Saved Succesfully"));
          dispatch(getBiddingQuoteByProjectId());
          dispatch(Actions.GetHeaderByProjectId(values.ProjectId));
        } else {
          dispatch(Actions.fetchError(res.data.ErrorMessage, "Sav Error!"));
        }
      })
      .catch((error) => {
        dispatch(Actions.fetchError(error.message, "Save Error!"));
      });
  } catch (err) {}
};

export const UpdateBiddingForm = (values) => async (dispatch, getState) => {
  const { projectGotById } = getState().createProject;
  const authdata = getState().auth;
  var data = values;
  try {
    return axios
      .post(`${Action.LOCALHOST}api/Bidding/update`, values)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(Actions.fetchSuccess("Sucessfully Update"));
          dispatch(Actions.GetHeaderByProjectId(values.ProjectId));
          if (
            (values?.ApprouveStatus === 1 || values?.ApprouveStatus === 2) &&
            projectGotById?.EmployeIdChargeProjet ===
              authdata?.authUser?.employeeid
          ) {
            dispatch(
              Actions.changeStatus(values?.BiddingId, 1, "BQ", null, null, true)
            ).then(() => {
              dispatch(getBiddingQuoteByProjectId());
            });
          } else {
            dispatch(getBiddingQuoteByProjectId());
          }
        } else {
          dispatch(Actions.fetchError(res.data.ErrorMessage, "Update Error!"));
        }
      })
      .catch((error) => {
        dispatch(Actions.fetchError(error.message, "Update Error!"));
      });
  } catch (err) {}
};

export const GetBiddingById = (biddingId) => async (dispatch) => {
  return axios
    .get(`${Action.LOCALHOST}api/Bidding/details/${biddingId}`)
    .then((res) => {
      if (res.data.Payload) {
        dispatch({
          type: Action.SET_BIDDING_BY_ID,
          payload: res.data.Payload,
        });
      } else {
        return dispatch({
          type: Action.SHOW_MESSAGE,
          payload: <IntlMessages id="Common.DataFetchError" />,
        });
      }
    });
};
export const DeleteBiddingById = (biddingId) => {
  return (dispatch, state) => {
    const { projectGotById } = state().createProject;
    return axios
      .post(`${Action.LOCALHOST}api/Bidding/delete/${biddingId}`)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(getBiddingQuoteByProjectId());
          //   dispatch(Actions.GetHeaderByProjectId(projectGotById.IdProjet));
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.SuccessDelete" />)
          );
          return true;
        } else {
          dispatch(
            Actions.fetchError(
              "Failed Deleted",
              <IntlMessages id="Common.FailDelete" />
            )
          );
          return false;
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Common.FailDelete" />)
        );
        return false;
      });
  };
};

export const RejetBiddingQuote = (biddingId, rejectedReason, formId) => {
  return (dispatch, state) => {
    return axios
      .post(
        `${Action.LOCALHOST}api/Project/RejectRequest/${biddingId}/${formId}`,
        `"${rejectedReason}"`
      )
      .then((res) => {
        if (res.data.Payload) {
          dispatch(getBiddingQuoteByProjectId());
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Bidding.Modal.Succesfull" />)
          );
          return true;
        } else {
          dispatch(
            Actions.fetchError(
              <IntlMessages id="Bidding.Modal.RejectionFailed" />,
              <IntlMessages id="Bidding.Modal.RejectionFailed" />
            )
          );
          return false;
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch(
          Actions.fetchError(
            errors,
            <IntlMessages id="Bidding.Modal.RejectionFailed" />
          )
        );
        return false;
      });
  };
};

export const GetDistributionDetails = (projectId) => {
  return (dispatch, getState) => {
    return axios
      .get(`${Action.LOCALHOST}api/Bidding/distribution/${projectId}`)
      .then((res) => {
        if (res.data.Payload) {
          return batch(() => {
            dispatch({
              type: Action.GET_DISTRIBUTION_DETAILS,
              payload: res.data.Payload,
            });
          });
        } else {
          dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const SaveDistribution = (values) => async (dispatch) => {
  try {
    return axios
      .post(`${Action.LOCALHOST}api/Bidding/distribution`, values)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(Actions.fetchSuccess("Saved Succesfully"));
        } else {
          dispatch(Actions.fetchError(res.data.ErrorMessage, "Sav Error!"));
        }
      })
      .catch((error) => {
        dispatch(Actions.fetchError(error.message, "Save Error!"));
      });
  } catch (err) {}
};
export const ResetBiddingById = () => {
  return {
    type: Action.RESET_BIDDING_BY_ID,
  };
};

export const GetBillingMethodLabLookup = () => async (dispatch) => {
  return axios
    .get(`${Action.LOCALHOST}api/Lookup/BillingMethodLabLookuop`)
    .then((res) => {
      if (res.data.Payload) {
        dispatch({
          type: Action.GET_BILLING_METHOD_LAB,
          payload: res.data.Payload,
        });
      } else {
        return dispatch({
          type: Action.SHOW_MESSAGE,
          payload: <IntlMessages id="Common.DataFetchError" />,
        });
      }
    });
};
export const GetListTrials = () => async (dispatch) => {
  return axios
    .get(`${Action.LOCALHOST}api/Bidding/GetListTrials`)
    .then((res) => {
      if (res.data.Payload) {
        dispatch({
          type: Action.GET_LIST_TRAILS,
          payload: res.data.Payload,
        });
      } else {
        return dispatch({
          type: Action.SHOW_MESSAGE,
          payload: <IntlMessages id="Common.DataFetchError" />,
        });
      }
    });
};
export const GetListEquipVehicles = () => async (dispatch) => {
  return axios
    .get(`${Action.LOCALHOST}api/Bidding/GetListEquipVehicles`)
    .then((res) => {
      if (res.data.Payload) {
        dispatch({
          type: Action.GET_LIST_EQUIPMENT_VEHICLES,
          payload: res.data.Payload,
        });
      } else {
        return dispatch({
          type: Action.SHOW_MESSAGE,
          payload: <IntlMessages id="Common.DataFetchError" />,
        });
      }
    });
};
