import * as Action from "../../constants/ActionTypes";
import * as Actions from "./index";
import { batch } from "react-redux";
import axios from "util/Api";
import moment from "moment";
import React from "react";
// import { useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
// const history = useHistory();
export const getPhaseOffer = () => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;
    if (projectGotById) {
      return axios
        .get(
          `${Action.LOCALHOST}api/PhaseOffre/Project/${projectGotById.IdProjet}`
        )
        .then((res) => {
          if (res.data.Payload) {
            return batch(() => {
              dispatch({
                type: Action.GET_PROJECT_PHASE_OFFER,
                payload: res.data.Payload,
              });
            });
          } else {
            dispatch({
              type: Action.SHOW_MESSAGE,
              payload: <IntlMessages id="Common.DataFetchError" />,
            });
          }
        })
        .catch((err) => {
          const errors = err.StatusDesc;
          dispatch({
            type: Action.FETCH_ERROR,
            payload: errors,
          });
        });
    }
  };
};
export const deletePhaseOffer = (id) => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;
    return axios
      .delete(`${Action.LOCALHOST}api/PhaseOffre/${id}`)
      .then((res) => {
        console.log("123 ", res);
        if (res.data.Payload) {
          dispatch(Actions.GetHeaderByProjectId(projectGotById.IdProjet));
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.SuccessDelete" />)
          );
          return dispatch(getPhaseOffer());
        } else {
          dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const addIdentifierInPhaseOffer = (name, file) => {
  return {
    type: Action.ADD_IDENTIFIER_PHASE_OFFER,
    name,
    payload: file,
  };
};
export const savePhaseOffers = (data) => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;
    const { termesreference } = getState().PhaseOffer;
    let obj = {
      ...data,
      idProjet:
        projectGotById !== null ? projectGotById.IdProjet : data.idProjet,
      deposit_date: data.deposit_date.format("YYYY-MM-DD"),
    };
    return axios
      .post(`${Action.LOCALHOST}api/PhaseOffre/Create`, obj)
      .then((res) => {
        console.log(res.data);
        if (res.data.IsSuccess) {
          dispatch(Actions.GetHeaderByProjectId(obj.idProjet));
          return Promise.all([
            dispatch(Actions.commonUpload("PhaseOffre", res.data.Payload)),
          ]).then(() => {
            dispatch(getPhaseOffer());
            dispatch(Actions.fetchSuccess(<IntlMessages id="Offer.Create" />));
            return res.data.Payload;
          });
        } else {
          dispatch(
            Actions.fetchError(
              "Saved Faild!",
              <IntlMessages id="Offer.NotCreate" />
            )
          );
          return false;
        }
      })
      .catch((err) => {
        dispatch(
          Actions.fetchError(err.message, <IntlMessages id="Offer.NotCreate" />)
        );
        return false;
      });
  };
};
export const updatePhaseOffer = (val) => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;
    const { data } = getState().PhaseOffer;
    let obj = {
      ...val,
      idProjet:
        projectGotById !== undefined
          ? projectGotById !== null
            ? projectGotById.IdProjet
            : val.idProjet ?? val.IdProjet
          : val.idProjet ?? val.IdProjet,
      idPhaseOffre: data.IdPhaseOffre,
      IsPhaseClose: data.IsPhaseClose === "true" ? true : false,
      deposit_date: val.deposit_date.format("YYYY-MM-DD"),
    };
    return axios
      .post(`${Action.LOCALHOST}api/PhaseOffre/Update`, obj)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(Actions.fetchSuccess(<IntlMessages id="Common.Update" />));
          dispatch(Actions.commonUpload("PhaseOffre", data.IdPhaseOffre));
          dispatch(Actions.GetHeaderByProjectId(obj.idProjet));
        } else {
          dispatch(
            Actions.fetchError(
              "Updated Failed",
              <IntlMessages id="Common.UpdateError" />
            )
          );
        }
        return true;
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Common.UpdateError" />)
        );
        return false;
      });
  };
};
export const getPhaseOfferById = (id) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/PhaseOffre/${id}`)
      .then((res) => {
        console.log("Response", res);
        if (res.data.Payload) {
          let attachement = res.data.Payload.lstAttachment;
          return batch(() => {
            dispatch({
              type: Action.GET_SPECIFIC_PHASE_PROJECT,
              payload: res.data.Payload,
            });
            dispatch(Actions.handleGetUploads(attachement, "PhaseOffre"));
          });
        } else {
          dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const dynamicAttachementPhaseOffre = (name, value) => {
  return {
    type: Action.DYNAMIC_PHASE_KEY_IDENTIFIER,
    payload: value,
    name,
  };
};
export const resetPhaseOffreForm = () => {
  return {
    type: Action.RESET_FORM_PHASE_OFFRE,
  };
};
export const transformToProject = (val) => {
  return (dispatch, getState) => {
    const { data } = getState().PhaseOffer;
    const { projectGotById } = getState().createProject;
    var obj;
    if (data.IdPhaseOffre) {
      obj = {
        ...val,
        idProjet:
          projectGotById !== undefined
            ? projectGotById !== null
              ? projectGotById.IdProjet
              : val.idProjet ?? val.IdProjet
            : val.idProjet ?? val.IdProjet,
        idPhaseOffre: data.IdPhaseOffre,
        IsPhaseClose: data.IsPhaseClose === "true" ? true : false,
        deposit_date: val.deposit_date.format("YYYY-MM-DD"),
      };
    } else {
      obj = {
        ...data,
        idProjet:
          projectGotById !== null ? projectGotById.IdProjet : data.idProjet,
        deposit_date: data.deposit_date.format("YYYY-MM-DD"),
      };
    }

    return axios
      .post(
        `${Action.LOCALHOST}api/PhaseOffre/TransfromToProjectByPhaseOffer`,
        obj
      )
      .then((res) => {
        console.log("Response", res);
        if (res.data.Payload) {
          dispatch({
            type: Action.TRANSFROM_PHASEOFFER_TO_PROJECT,
            payload: res.data.Payload,
          });
          dispatch(Actions.resetCreateForm());
          var obj = {};
          obj.IsValid = true;
          // obj.projectId = res.data.Payload;
          return obj;
        } else {
          dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
        var obj = {};
        obj.IsValid = false;

        return obj;
      });
  };
};

export const resettTransformToProject = () => {
  return {
    type: Action.RESET_TRANSFROM_PHASEOFFER_TO_PROJECT,
  };
};
export const CheckDuplicatePONumber = (phaseNo, projectId, phaseRefId) => {
  return (dispatch, getState) => {
    return axios
      .get(
        `${Action.LOCALHOST}api/PhaseOffre/CheckDuplicatePONumber/${phaseNo}/${projectId}/${phaseRefId}`
      )
      .then((res) => {
        return res.data.Payload;
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
