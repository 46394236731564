import { INIT_URL } from "../../constants/ActionTypes";
import * as Action from "../../constants/ActionTypes";
import _ from "lodash";
import axios from "util/Api";
import * as Actions from "./index";
import { batch } from "react-redux";

import React from "react";
import IntlMessages from "util/IntlMessages";
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
export const SaveNewPost = (data) => async (dispatch) => {
  let updatedData = {
    ...data,

    AverageInternalSurvey: parseFloat(data.AverageInternalSurvey),
  };

  return axios
    .post(`${Action.LOCALHOST}api/NewPostMortem/Create`, updatedData)
    .then((res) => {
      dispatch(Actions.fetchSuccess(<IntlMessages id="PostMortem.Create" />));
      dispatch(Actions.commonUpload("NewPostMortem", updatedData.id));
      dispatch(GetNewPostMortemByProjectId(data.projectNo));
      return res;
    })
    .catch(() => {
      dispatch(
        Actions.fetchError(
          "Saved Faild!",
          <IntlMessages id="PostMortem.NotCreate" />
        )
      );
    });
};

export const UpdateNewPost = (data) => async (dispatch) => {
  let updatedData = {
    ...data,
    AverageInternalSurvey: parseFloat(data.AverageInternalSurvey),
  };
  return axios
    .put(`${Action.LOCALHOST}api/NewPostMortem/Update`, updatedData)
    .then((res) => {
      dispatch(Actions.fetchSuccess(<IntlMessages id="PostMortem.Update" />));
      dispatch(Actions.commonUpload("NewPostMortem", data.projectNo));
      dispatch(GetNewPostMortemByProjectId(data.projectNo));
    })
    .catch(() => {
      dispatch(
        Actions.fetchError(
          "Updated Faild!",
          <IntlMessages id="PostMortem.NotCreate" />
        )
      );
    });
};

// export const GetNewPostMortemById = (id) => (dispatch) => {
//   return axios
//     .get(`${Action.LOCALHOST}api/NewPostMortem/${id}`)
//     .then((res) => {
//       return dispatch({
//         type: Action.GET_NEWPOSTMORTEM_LIST,
//         payload: res.data.Payload,
//       });
//     })
//     .catch((err) => {
//       const errors = err.StatusDesc;
//       return dispatch({
//         type: Action.FETCH_ERROR,
//         payload: errors,
//       });
//       console.log("Error****:", errors);
//     });
// };

// export const GetNewPostMortemByProjectId = (projectId) => (dispatch) => {
//   return axios
//     .get(
//       `${Action.LOCALHOST}api/NewPostMortem/GetNewPostMortemByProjectId/${projectId}`
//     )
//     .then((res) => {
//       return dispatch({
//         type: Action.GET_NEWPOSTMORTEM_BY_PROJECTID,
//         payload: res.data.Payload,
//       });
//       let attachement = res.data.Payload.lstAttachment;
//       dispatch(Actions.handleGetUploads(attachement, "NewPostMortem"));
//     })
//     .catch((err) => {
//       const errors = err.StatusDesc;
//       return dispatch({
//         type: Action.FETCH_ERROR,
//         payload: errors,
//       });

//       console.log("Error****:", errors);
//     });
// };

export const GetNewManagerHistoryByProjectId = (projectId) => (dispatch) => {
  return axios
    .get(
      `${Action.LOCALHOST}api/History/GetManagersHistoryForProject/${projectId}`
    )
    .then((res) => {
      return dispatch({
        type: Action.GET_MANAGER_HISTORY_BY_PROJECTID,
        payload: res.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: Action.FETCH_ERROR,
        payload: errors,
      });
      console.log("Error****:", errors);
    });
};

export const GetLookupPercentage = (projectId) => (dispatch) => {
  return axios
    .get(`${Action.LOCALHOST}api/NewPostMortem/GetLookupPercentage`)
    .then((res) => {
      return dispatch({
        type: Action.GET_NEWPOSTMORTEM_PERCENTAGE,
        payload: res.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: Action.FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};

export const uploadIdentifierByIdPostmortem = (name, file) => {
  return {
    type: Action.UPLOAD_IDENTIFIER_BY_NEW_POSTMORTEM,
    name,
    payload: file,
  };
};

export const updateIdentifierByIdPostmortem = (name, file) => {
  return {
    type: Action.UPDATE_IDENTIFIER_BY_NEW_POSTMORTEM,
    name,
    payload: file,
  };
};
export const resetNewPostmortemForm = () => {
  return {
    type: Action.RESET_NEWPOSTMORTEM_FORM,
  };
};

export const GetNewPostMortemById = (id) => (dispatch) => {
  return axios
    .get(`${Action.LOCALHOST}api/NewPostMortem/${id}`)
    .then((res) => {
      return batch(() => {
        dispatch({
          type: Action.GET_NEWPOSTMORTEM_LIST,
          payload: res.data.Payload,
        });
        let attachement = res.data.Payload.lstAttachment;
        dispatch(Actions.handleGetUploads(attachement, "NewPostMortem"));
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: Action.FETCH_ERROR,
        payload: errors,
      });
      console.log("Error****:", errors);
    });
};
export const GetNewPostMortemByProjectId = (projectId) => (dispatch) => {
  return axios
    .get(
      `${Action.LOCALHOST}api/NewPostMortem/GetNewPostMortemByProjectId/${projectId}`
    )
    .then((res) => {
      if (res.data.IsSuccess) {
        return batch(() => {
          dispatch({
            type: Action.GET_NEWPOSTMORTEM_BY_PROJECTID,
            payload: res.data.Payload,
          });
          let attachement = res.data.Payload.lstAttachment;
          dispatch(Actions.handleGetUploads(attachement, "NewPostMortem"));
        });
      } else throw res.data;
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: Action.FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};
