import * as Action from "../../constants/ActionTypes";
const initState = {
  TableData: [],
  table: [],
  edit: null,
  innerEdit: null,
  DetailedAnalysisAttachment: null,
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.RISK_ANALYSIS_EDIT_INNER_TABLE: {
      return {
        ...state,
        innerEdit: action.payload,
      };
    }
    case Action.GET_RISK_ANALYSIS_BY_ID: {
      return {
        ...state,
        TableData: action.payload,
      };
    }
    case Action.ADD_RISK_TABLE_DATA: {
      let arr = state.table;
      arr.push(action.payload);
      console.log(action.payload);
      return {
        ...state,
        table: arr,
      };
    }
    case Action.UPDATE_RISK_ANALYSIS_INNER_TABLE: {
      return {
        ...state,
        table: action.payload,
      };
    }
    case Action.RISK_ANALYSIS_TABLE_EDIT_DATA: {
      return {
        ...state,
        edit: action.payload,
      };
    }
    case Action.RISK_ANALYSIS_RESET_FORM_DATA: {
      return {
        ...state,
        table: [],
        edit: null,
        innerEdit: null,
        DetailedAnalysisAttachment: null,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
