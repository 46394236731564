import * as Action from "../../constants/ActionTypes";
import _ from "lodash";
import axios from "util/Api";
import React from "react";
import IntlMessages from "util/IntlMessages";
import * as Actions from "./index";
import { batch } from "react-redux";
export const getAccountList = (
  formid,
  formtype,
  department,
  title,
  resposibleNames,
  projectNumber,
  statutProjet
) => {
  let formidparam = formid || "";
  let formtypeparam = formtype || "";
  department = department || "";
  title = title || "";
  resposibleNames = resposibleNames || "";
  projectNumber = projectNumber || "";
  statutProjet = statutProjet || "";
  return (dispatch) => {
    return axios
      .get(
        `${Action.LOCALHOST}api/Project/GetListByAccounting?formid=` +
          formidparam +
          "&formtype=" +
          formtypeparam +
          "&department=" +
          department +
          "&title=" +
          title +
          "&resposibleNames=" +
          resposibleNames +
          "&projectNumber=" +
          projectNumber +
          "&statutProjet=" +
          statutProjet
      )
      .then((res) => {
        console.log("Accounts data", res);
        if (res.data.Payload) {
          const payload = _.groupBy(res.data.Payload, function (o) {
            return o.NoProjet;
          });
          return dispatch({
            type: Action.GET_ACCOUNTS_LIST,
            payload: payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
