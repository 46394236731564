import * as Action from "../../constants/ActionTypes";
const initState = {
  Panels: [],
  pages: 1,
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.GET_LIST_BY_CUSTOMER: {
      return {
        ...state,
        pages: action.payload.TotalRecordCount,
        Panels: action.payload.Payload,
      };
    }
    case Action.RUN_TIME_KEY_LIST_BY_CUSTOMER: {
      return {
        ...state,
        [action.payload.key]: {
          data: action.payload.data,
          page: action.payload.page,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
