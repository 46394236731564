import * as Action from "../../constants/ActionTypes";
const initState = {
  Data: null,
  ProjectManagerHistory: ''
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.GET_POSTMORTEM_LIST: {
      return {
        ...state,
        PostMortemById: action.payload,
      };
    }
    case Action.GET_POSTMORTEM_BY_PROJECTID: {
      return {
        ...state,
        PostMortem: action.payload,
      };
    }
    case Action.GET_MANAGER_HISTORY_BY_PROJECTID: {
      return {
        ...state,
        ProjectManagerHistory: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default reducer;
