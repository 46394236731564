import * as Action from "../../constants/ActionTypes";
const initState = {
  projectDetails: {},
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.GET_PROJECT_DETAILS: {
      return {
        ...state,
        projectDetails: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
