import * as Action from "../../constants/ActionTypes";
const init = {
  table: [],
  data: null,
  termesreference: null,
  precisionsreference: null,
  AttachementSousTraitants: null,
  Offre: null,
  octroioui: null,
  octroinon: null,
};
const reducer = (state = init, action) => {
  switch (action.type) {
    case Action.GET_PROJECT_PHASE_OFFER: {
      return {
        ...state,
        table: action.payload,
      };
    }
    case Action.RESET_FORM_PHASE_OFFRE: {
      return {
        ...state,
        data: null,
        termesreference: null,
        precisionsreference: null,
        AttachementSousTraitants: null,
        Offre: null,
        octroioui: null,
        octroinon: null,
        table: [],
      };
    }
    case Action.ADD_IDENTIFIER_PHASE_OFFER: {
      return {
        ...state,
        [action.name]: action.payload,
      };
    }
    case Action.DYNAMIC_PHASE_KEY_IDENTIFIER: {
      let arr = state[action.name];
      if (arr === null || arr === undefined) {
        arr = [];
      }
      arr.push(action.payload);
      return {
        ...state,
        [action.name]: arr,
      };
    }
    case Action.GET_SPECIFIC_PHASE_PROJECT: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case Action.TRANSFROM_PHASEOFFER_TO_PROJECT: {
      return {
        ...state,
        phaseOffrforProject: action.payload,
      };
    }
    case Action.RESET_TRANSFROM_PHASEOFFER_TO_PROJECT: {
      return {
        ...state,
        phaseOffrforProject: null,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
