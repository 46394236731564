import * as Action from "../../constants/ActionTypes";
import * as Actions from "./index";
import _ from "lodash";
import axios from "util/Api";
import React from "react";
import IntlMessages from "util/IntlMessages";

export const getApproveHistory = (id, name, action) => async (dispatch) => {
  try {
    dispatch({ type: Action.FETCH_START });
    console.log("inside of transactions history");
    const res = await axios.get(
      `${Action.LOCALHOST}api/History/Transaction/${id}/${name}/${action}`
    );
    //dispatch({ type: Action.TRANSACTIONS_HISTORY, payload: "res.data.Payload" });

    if (res.data.IsSuccess) {
      dispatch({ type: Action.HISTORY_APPROVE, payload: res.data.Payload });

      if (res.data.IsSuccess) {
        dispatch({ type: Action.HISTORY_APPROVE, payload: res.data.Payload });

        dispatch({ type: Action.FETCH_SUCCESS });
      } else {
        dispatch({
          type: Action.SHOW_MESSAGE,
          payload: <IntlMessages id="Common.DataFetchError" />,
        });
      }
    }
  } catch (err) {
    const errors = err.StatusDesc;
    return dispatch({
      type: Action.FETCH_ERROR,
      payload: errors,
    });
  }
};

export const getTransactionHistory = (id, name) => async (dispatch) => {
  try {
    console.log("inside of transactions history");
    dispatch(getModifiedFields(id, name));
    const res = await axios.get(
      `${Action.LOCALHOST}api/History/Archive/${id}/${name}`
    );
    //dispatch({ type: Action.TRANSACTIONS_HISTORY, payload: "res.data.Payload" });

    if (res.data.IsSuccess) {
      dispatch({ type: Action.TRANSACTIONS, payload: res.data.Payload });
    } else {
      dispatch({
        type: Action.SHOW_MESSAGE,
        payload: "DATA FETCH ERROR",
      });
    }
  } catch (err) {
    const errors = err.StatusDesc;
    return dispatch({
      type: Action.FETCH_ERROR,
      payload: errors,
    });
  }
};

export const resetModifiedFields = () => {
  return {
    type: Action.RESET_MODIFIEDFIELDS,
    payload: null,
  };
};

export const getModifiedFields = (id, name) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${Action.LOCALHOST}api/History/GetModifiedFields/${id}/${name}`
    );
    if (res.data.IsSuccess) {
      dispatch({ type: Action.MODIFIEDFIELDS, payload: res.data.Payload });
    } else {
      dispatch({
        type: Action.SHOW_MESSAGE,
        payload: <IntlMessages id="Common.DataFetchError" />,
      });
    }
  } catch (err) {
    const errors = err.StatusDesc;
    return dispatch({
      type: Action.FETCH_ERROR,
      payload: errors,
    });
  }
};
