import * as Action from "../../constants/ActionTypes";
const initState = {
  StatePannels: [],
  StateData: {},
  pages: 1,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.GET_LIST_BY_STATE: {
      return {
        ...state,
        pages: action.payload.TotalRecordCount,
        StatePannels: action.payload.Payload,
      };
    }

    case Action.RUN_TIME_STATE_KEY_LIST: {
      return {
        ...state,
        StateData: {
          [action.payload.key]: {
            data: action.payload.data,
            page: action.payload.page,
          },
        },
      };
    }
    case Action.ResetManagers: {
      return {
        ...state,
        Panels: [],
        pages: 1,
        Data: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
