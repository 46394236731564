import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  UPLOAD_PUSH_IDENTIIFIER,
  TOGGLE_LOADER,
  SET_LOADER,
  TOGGLE_EMPLOYEE_LOCKUP_LOADER,
  TOGGLE_COMPANY_LOCKUP_LOADER,
  TOGGLE_PROGRESS_BAR_LOADER,
  GET_ALL_LOOKUPS_BY_LANGUAGE,
  SHOW_NO_ACCESS_MESSAGE,
  GET_OFFICE_LOCATION,
  GET_BILLING_TECHNICIAN,
  GET_ALL_ACTIVEINACTIVE_LOOKUPS_BY_LANGUAGE,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  message: "",
  loader: false,
  employeeLockupLoader: false,
  companyLockupLoader: false,
  BarLoader: false,
  requirAccessMessage: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_PROGRESS_BAR_LOADER: {
      return {
        ...state,
        BarLoader: !state.BarLoader,
      };
    }
    case TOGGLE_LOADER: {
      return {
        ...state,
        loader: !state.loader,
      };
    }
    case SET_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case FETCH_START: {
      return { ...state, error: "", message: "action.payload", loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: "", message: "", loading: false };
    }
    case SHOW_MESSAGE: {
      return { ...state, error: "", message: action.payload, loading: false };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload, message: "" };
    }
    case SHOW_NO_ACCESS_MESSAGE: {
      return {
        ...state,
        requirAccessMessage: action.payload,
      };
    }
    case HIDE_MESSAGE: {
      return { ...state, loading: false, error: "", message: "" };
    }
    case TOGGLE_COMPANY_LOCKUP_LOADER: {
      return {
        ...state,
        companyLockupLoader: action.payload,
      };
    }
    case TOGGLE_EMPLOYEE_LOCKUP_LOADER: {
      return {
        ...state,
        employeeLockupLoader: action.payload,
      };
    }
    case GET_ALL_LOOKUPS_BY_LANGUAGE: {
      return {
        ...state,
        projectTypeLookupData: action.payload.filter(
          (a) => a.Type === "ProjectType"
        ),
        projectDomainLookupData: action.payload.filter(
          (a) => a.Type === "ProjectDomain"
        ),
        projectSecurityLookupData: action.payload.filter(
          (a) => a.Type === "SecurityLevel"
        ),
        typeOfClientLookupData: action.payload.filter(
          (a) => a.Type === "TypeOfClient" || a.key === "1000"
        ),
        postTechnicalLookupData: action.payload.filter(
          (a) => a.Type === "Postmortem_TechnicalOffer" || a.key === "1000"
        ),
        postFinancialLookupData: action.payload.filter(
          (a) => a.Type === "PostMortem_Financial" || a.key === "1000"
        ),
        postOthersLookupData: action.payload.filter(
          (a) => a.Type === "PostMortem_Others" || a.key === "1000"
        ),
        methodBillingLookupData: action.payload.filter(
          (a) => a.Type === "MethodBilling"
        ),
        typeBillingLookupData: action.payload.filter(
          (a) => a.Type === "TypeBilling"
        ),
        typeBillingExpSubLookupData: action.payload.filter(
          (a) => a.Type === "TypeBillingExpSub"
        ),
        typeBillingEquipLookupData: action.payload.filter(
          (a) => a.Type === "TypeBillingEquip"
        ),
        typeBillingVehLookupData: action.payload.filter(
          (a) => a.Type === "TypeBillingVeh"
        ),
        typeBillingTestsLookupData: action.payload.filter(
          (a) => a.Type === "TypeBillingTests"
        ),
        typeProjectPhaseMarkup: action.payload.filter(
          (a) => a.Type === "ProjectPhaseMarkup"
        ),
        budgetTypeLookupData: action.payload.filter(
          (a) => a.Type === "BudgetType" || a.key === "1000"
        ),
        statusLookupData: action.payload.filter(
          (a) => a.Type === "Status" || a.key === "1000"
        ),
        proposalType: action.payload.filter(
          (a) => a.Type === "Proposal type" || a.key === "1000"
        ),
        technicalContent: action.payload.filter(
          (a) => a.Type === "TechnicalContent"
        ),
        depositNoLookupData: action.payload.filter(
          (a) => a.Type === "DepositNo" || a.key === "1000"
        ),
        statusProjetLookupData: action.payload.filter(
          (a) => a.Type === "StatusProjet" || a.key === "1000"
        ),

        marketTypeLookupData: action.payload.filter(
          (a) => a.Type === "market_type" || a.key === "1000"
        ),

        subTypeLookupData: action.payload.filter((a) =>
          action.payload
            .filter((b) => b.Type === "market_type")
            .some((mt) => mt.Description === a.Type)
        ),
        invoiceHeadingLookupData: action.payload.filter(
          (a) => a.Type === "InvoiceHeading"
        ),
        allLookupData: action.payload,
        DescriptionActivities: action.payload.filter(
          (a) => a.Type === "DescriptionActivities"
        ),
        descriptionActivitiesLookupData: action.payload.filter(
          (a) => a.Type === "DescriptionActivities"
        ),
        opportunityLookupData: action.payload.filter(
          (a) => a.Type === "Opportunity_Status"
        ),
        opportunityDecisionReasonLookupData: action.payload.filter(
          (a) => a.Type === "Decision_Reason"
        ),
        approvalstatus: action.payload.filter(
          (a) => a.Type === "approvestatus"
        ),
        RisquesSSTLookupData: action.payload.filter(
          (a) => a.Type === "UnusualOHSRisks"
        ),
        companyLookupData: action.payload.filter(
          (a) => a.Type === "MandateInfo_SelectCompany"
        ),
        PlanificationMultSelectLookupData: action.payload.filter(
          (a) => a.Type === "PlanificationMultiSelect" || a.key === "1000"
        ),
        NewPostMortemLookupData: action.payload.filter(
          (a) => a.Type === "NewPostmortemQuestions"
        ),
        NewPostMortemRadioData: action.payload.filter(
          (a) => a.Type === "NewPostmortemRadio"
        ),
        NewPostMortemNotAvailableData: action.payload.filter(
          (a) => a.Type === "NotAvailableType"
        ),
      };
    }

    case GET_OFFICE_LOCATION: {
      return {
        ...state,
        officeLocationLookupData: action.payload,
      };
    }

    case GET_BILLING_TECHNICIAN: {
      return {
        ...state,
        billingTechnicianLookupData: action.payload,
      };
    }

    case GET_ALL_ACTIVEINACTIVE_LOOKUPS_BY_LANGUAGE: {
      return {
        ...state,
        allActiveInactiveLookupData: action.payload,
      };
    }

    default:
      return state;
  }
};
