import React from "react";
import ReactDOM from "react-dom";
import './App.css';

import NextApp from "./NextApp";
import registerServiceWorker, { unregister } from "./registerServiceWorker";
// Add this import:
import { AppContainer } from "react-hot-loader";
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0RhWn9XdXBWQWNdUk0=');

// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById("root")
  );
};

// Do this once
// registerServiceWorker();
unregister();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(NextApp);
  });
}
