import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router-dom";
const UserProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      {/*<li>My Account</li>
      <li>Connections</li>*/}
      <li
        onClick={() => {
          dispatch(userSignOut());
          history.push("/");
        }}
      >
        Logout
      </li>
    </ul>
  );
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src={localStorage.getItem("profilePhoto")}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name">
          {authUser?.displayname}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
