import React from "react";
import { useSelector } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import * as Action from "../../appRedux/actions";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { CalculatorOutlined } from "@ant-design/icons";
import IntlMessages from "../../util/IntlMessages";
import { OpportunityToggle, googleMapsToggle } from "../../constants/ActionTypes";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {
  let { navStyle, themeType, pathname } = useSelector(
    ({ settings }) => settings
  );

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          {/*<AppsNavigation/>*/}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            //mode="vertical"
          >
            {OpportunityToggle == "true" && (
              <SubMenu
                key="sample"
                //className={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-files" />
                    <span>
                      <IntlMessages id="sidebar.Opportunity" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="opportunities/newform">
                  <Link to="/opportunities/newform">
                    <IntlMessages id="sidebar.NewForm" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="opportunities/lastsevendays">
                  <Link to="/opportunities/lastsevendays">
                    <IntlMessages id="sidebar.LastSevenDays" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="opportunities/lasttwodays">
                  <Link to="/opportunities/lasttwodays">
                    <IntlMessages id="sidebar.LastTwoDays" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="opportunities/bydepartment">
                  <Link to="/opportunities/bydepartment">
                    <IntlMessages id="sidebar.Bydepartement" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="opportunities/myopportunities">
                  <Link to="/opportunities/myopportunities">
                    <IntlMessages id="sidebar.MyOpportunities" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="opportunities/all">
                  <Link to="/opportunities/all">
                    <IntlMessages id="sidebar.All" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="opportunities/alarm">
                  <Link to="/opportunities/alarm">
                    <IntlMessages id="sidebar.Alarm" />
                  </Link>
                </Menu.Item>
              </SubMenu>
            )}
            {Action.canAddNew() && (
              <Menu.Item key="sample">
                <Link to="/project/create/B1-F06">
                  <i className="icon icon-add" />
                  <span>
                    <IntlMessages id="sidebar.project.create" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {Action.canSeeDashboard() && (
              <Menu.Item key="sample">
                <Link to="/Dashboard">
                  <i className="icon icon-widgets" />
                  <IntlMessages id="sidebar.Dashboard" />
                </Link>
              </Menu.Item>
            )}
            {Action.canSeeMyApproveTab() && (
              <Menu.Item key="sample">
                <Link to="/myapprovals">
                  <i className="icon icon-check" />
                  <IntlMessages id="sidebar.MyApprovals" />
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="sample">
              <Link to="/project/fulllist">
                <i className="icon icon-listing-dbrd" />
                <span>
                  <IntlMessages id="sidebar.project.fulllist" />
                </span>
              </Link>
            </Menu.Item>
            {Action.canSeeApproveTab() && (
              <Menu.Item key="project/approval">
                <Link to="/project/approval">
                  <i className="icon icon-crm" />
                  <span>
                    <IntlMessages id="sidebar.project.approvals" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {Action.canSeeAccountingTab() && (
              <Menu.Item key="project/accounting" className="antcal-icon">
                <Link to="/project/accounting">
                  <CalculatorOutlined className="icon" />
                  <span>
                    <IntlMessages id="sidebar.project.accounting" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="project/listbyphaseoffer">
              <Link to="/project/listbyphaseoffer">
                <i className="icon icon-product-list" />
                <span>
                  <IntlMessages id="sidebar.project.listByPhaseOffer" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="project/listbycharge">
              <Link to="/project/listbycharge">
                <i className="icon icon-auth-screen" />
                <span>
                  <IntlMessages id="sidebar.project.listbycharge" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="project/listbycustomer">
              <Link to="/project/listbycustomer">
                <i className="icon icon-select" />
                <span>
                  <IntlMessages id="sidebar.project.listbycustomer" />
                </span>
              </Link>
            </Menu.Item>
            {googleMapsToggle && (<Menu.Item key="project/Map">
              <Link to="/project/Map">
                <i className="icon icon-map-google" />
                <span>
                  <IntlMessages id="sidebar.Map" />
                </span>
              </Link>
            </Menu.Item>)}
            {/* <SubMenu
              key="project"
              className={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {" "}
                  <i className="icon icon-files" />
                  <span>
                    <IntlMessages id="sidebar.project" />
                  </span>
                </span>
              }
            >
              <Menu.Item key="project/create/B1-F06">
                <Link to="/project/create/B1-F06">
                  <i className="icon icon-apps-new" />
                  <span>
                    <IntlMessages id="sidebar.project.create" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="project/approval">
                <Link to="/project/approval">
                  <i className="icon icon-crm" />
                  <span>
                    <IntlMessages id="sidebar.project.approvals" />
                  </span>
                </Link>
              </Menu.Item>
              {(Action.isAccountingUser() || Action.isDirector() || Action.isProjectManager() || Action.isAdmin()) && 
              <Menu.Item key="project/accounting">
                <Link to="/project/accounting">
                  <i className="icon icon-listing-dbrd" />
                  <span>
                    <IntlMessages id="sidebar.project.accounting" />
                  </span>
                </Link>
              </Menu.Item>}
              <Menu.Item key="project/fulllist">
                <Link to="/project/fulllist">
                  <i className="icon icon-listing-dbrd" />
                  <span>
                    <IntlMessages id="sidebar.project.fulllist" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="project/listbycharge">
                <Link to="/project/listbycharge">
                  <i className="icon icon-listing-dbrd" />
                  <span>
                    <IntlMessages id="sidebar.project.listbycharge" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="project/listbycustomer">
                <Link to="/project/listbycustomer">
                  <i className="icon icon-listing-dbrd" />
                  <span>
                    <IntlMessages id="sidebar.project.listbycustomer" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu> */}
            {/* <SubMenu
              key="offers"
              className={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {" "}
                  <i className="icon icon-files" />
                  <span>Offers</span>
                </span>
              }
            >
              <Menu.Item key="offer/create">
                <Link to="/offer/create">
                  <i className="icon icon-crm" />
                  <span>Create</span>
                </Link>
              </Menu.Item>
            </SubMenu> */}

            {/* <Menu.Item key="main/widgets">
              <Link to="/main/widgets">
                <i className="icon icon-widgets" />
                <span>
                  <IntlMessages id="sidebar.widgets" />
                </span>
              </Link>
            </Menu.Item> */}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
