import * as Action from "../../constants/ActionTypes";
import axios from "util/Api";
import * as Actions from "./index";
import { batch } from "react-redux";
import React from "react";
import IntlMessages from "util/IntlMessages";
export const getAvenantsByProjectId = (ID) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/Avenant/Project/${ID}`)
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.SET_AVENANT_TABLE_DATA,
            payload: res.data.Payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const getHistoryAvenant = (id) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/Audit/${id}/Avenant/APPROVE`)
      .then((res) => {
        if (res.data.IsSuccess) {
          return dispatch({
            type: Action.GET_HISTORY_OF_AVENANT,
            payload: res.data.Payload,
          });
        } else throw res.data;
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        console.log(err);
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Common.HistoryError" />)
        );
      });
  };
};
export const getTransectionAvenant = (id) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/Audit/${id}/Avenant/UPDATE`)
      .then((res) => {
        if (res.data.IsSuccess) {
          return dispatch({
            type: Action.GET_TRANSECTION_HISTORY_OF_AVENANT,
            payload: res.data.Payload,
          });
        } else throw res.data;
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        console.log(err);
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Common.HistoryError" />)
        );
      });
  };
};
export const deleteAvenant = (id, projectId) => {
  return (dispatch) => {
    console.log("DeleteAvenant with ID", id);
    return axios
      .delete(`${Action.LOCALHOST}api/Avenant/${id}`)
      .then((res) => {
        if (res.data.IsSuccess) {
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.SuccessDelete" />)
          );
          dispatch(
            Actions.GetHeaderByProjectId(
              projectId !== undefined ? projectId : 0
            )
          );
          return dispatch(
            getAvenantsByProjectId(projectId !== undefined ? projectId : 0)
          );
        } else throw res.data;
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        console.log(err);
        dispatch(
          Actions.fetchError(
            errors,
            <IntlMessages id="Avenant.SuccessFailed" />
          )
        );
      });
  };
};

export const saveAvenant = (val) => {
  return (dispatch, getState) => {
    const value = getState().Avenant;
    let obj = {
      ...val,
      // EndorsementContract: val.EndorsementContract === true ? true : false,
      // EndorsementOther: val.EndorsementOther === true ? true : false,
      // AmendmentReviewed: val.AmendmentReviewed === true ? true : false,
      // AmendTerms: val.AmendTerms === true ? true : false,
    };
    return axios
      .post(`${Action.LOCALHOST}api/Avenant/Create`, obj)
      .then((res) => {
        if (res.data.IsSuccess) {
          batch(() => {
            dispatch(
              getAvenantsByProjectId(
                val.IdProject !== undefined ? val.IdProject : 0
              )
            );
            dispatch(
              Actions.GetHeaderByProjectId(
                val.IdProject !== undefined ? val.IdProject : 0
              )
            );
            dispatch(Actions.commonUpload("Avenant", res.data.Payload));
          });
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.DataSuccess" />)
          );
          //return res;
          return res.data.Payload;
        } else throw res.data;
      })
      .catch((err) => {
        const errors = err.StatusDesc || err.title;
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Avenant.SaveError" />)
        );
        return false;
      });
  };
};

export const getAvenantByID = (ID) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/Avenant/${ID}`)
      .then((res) => {
        if (res.data.IsSuccess) {
          return batch(() => {
            dispatch({
              type: Action.GET_AVENANTS_BY_ID,
              payload: res.data.Payload,
            });
            let attachement = res.data.Payload.lstAttachment;
            dispatch(Actions.handleGetUploads(attachement, "Avenant"));
          });
        } else throw res.data;
      })
      .catch((err) => {
        const errors = err.StatusDesc || err.title;
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Avenant.Error" />)
        );
        return false;
      });
  };
};

export const updataAvenant = (val) => {
  return (dispatch, getState) => {
    const Phases = val.Phases.map((value) => {
      return {
        BD: value.BD,
        BH: value.BH,
        BST: value.BST,
        Total: value.Total,
      };
    });
    return axios
      .put(`${Action.LOCALHOST}api/Avenant/Update`, { ...val, Phases })
      .then((res) => {
        if (res.data.IsSuccess) {
          dispatch(
            getAvenantsByProjectId(
              val.IdProject !== undefined ? val.IdProject : 0
            )
          );
          dispatch(
            Actions.GetHeaderByProjectId(
              val.IdProject !== undefined ? val.IdProject : 0
            )
          );
          dispatch(Actions.fetchSuccess(<IntlMessages id="Avenant.Success" />));
          dispatch(Actions.commonUpload("Avenant", val.IdAvenant));
          return true;
        } else throw res.data;
      })
      .catch((err) => {
        const errors = err.StatusDesc || err.title;
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Avenant.Failes" />)
        );
        return false;
      });
  };
};
export const uploadIdentifierByIdAvenant = (name, file) => {
  return {
    type: Action.UPLOAD_IDENTIFIER_BY_KEY_AVENANT,
    name,
    payload: file,
  };
};
export const updateIdentifierByIdAvenant = (name, file) => {
  return {
    type: Action.UPDATE_IDENTIFIER_BY_KEY_AVENANT,
    name,
    payload: file,
  };
};
export const resetAvenantForm = () => {
  return {
    type: Action.RESET_AVENANT_FORM,
  };
};
