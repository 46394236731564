import * as Action from "../../constants/ActionTypes";
import axios from "util/Api";
import React from "react";
import IntlMessages from "util/IntlMessages";

export const getListByState = (
  departmentId,
  page,
  size,
  projectNumber = "",
  daveOpening = "",
  daveClosing = "",
  responsible = "",
  SearchKey = "",
  ClientNom = ""
) => {
  console.log(
    `dept ID = ${departmentId} , searchkey = ${SearchKey} responsible = ${responsible}`
  );
  return (dispatch) => {
    return axios
      .post(`${Action.LOCALHOST}api/PhaseOffre/GetAllListByState`, {
        pageNumber: page,
        pageSize: size || 10,
        DepartmentId: departmentId,
        projectNumber: projectNumber,
        daveOpening: daveOpening,
        daveClosing: daveClosing,
        responsible: responsible,
        SearchKey: SearchKey,
        ClientNom: ClientNom,
      })
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_LIST_BY_STATE,
            payload: res.data,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const runtimeStateKey = (
  departmentId,
  key,
  page,
  projectNumber = "",
  daveOpening = "",
  daveClosing = "",
  responsible = "",
  SearchKey = "",
  ClientNom = ""
) => {
  return (dispatch) => {
    return axios
      .post(`${Action.LOCALHOST}api/PhaseOffre/GetListByStateDetails`, {
        pageNumber: page,
        pageSize: 50,
        searchValue: key,
        DepartmentId: departmentId,
        projectNumber: projectNumber,
        daveOpening: daveOpening,
        daveClosing: daveClosing,
        responsible: responsible,
        SearchKey: SearchKey,
        ClientNom: ClientNom,
      })
      .then((res) => {
        const value = key.split(" ")[0] + departmentId;
        if (res.data.Payload) {
          return dispatch({
            type: Action.RUN_TIME_STATE_KEY_LIST,
            payload: {
              data: res.data.Payload,
              key: value,
              page: res.data.TotalRecordCount,
            },
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        console.log(err);
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
