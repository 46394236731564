import * as Action from "../../constants/ActionTypes";
import axios from "util/Api";
import uuid from "react-uuid";
import * as Actions from "./index";
import { batch } from "react-redux";
import React from "react";
import IntlMessages from "util/IntlMessages";
export const getReviewFormTableData = (ID) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/ProjectReview/list/${ID}`)
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.SET_REVIEWFORM_TABLE_DATA,
            payload: res.data.Payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const getReviewFormByID = (ID) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/ProjectReview/${ID}`)
      .then((res) => {
        if (res.data.IsSuccess) {
          return batch(() => {
            dispatch({
              type: Action.GET_REVIEWFORM_BY_ID,
              payload: res.data.Payload,
            });

            let attachement = res.data.Payload.ReviewAttachment;
            dispatch(Actions.handleGetUploads(attachement, "Review"));
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const SaveReviewForm = (val) => {
  let obj = {
    RevueDeProjet: {
      Id: 0,
      IdProjet: val?.idProjet,
      Progress: val?.progress,
      RemarksGeneral: val?.remarkgen,
      ReviewNumber: val?.reviewNumber,
    },
    Participants: [],
    Elements: [],
  };
  var i;
  for (i = 1; i <= 5; i++) {
    if (val["participant" + i]) {
      obj.Participants.push({
        Id: 0,
        ReviewId: 0,
        ParticipantEmployeeId: parseInt(val["participant" + i]),
        Discipline: val["discipline" + i] ?? "",
      });
    }
  }
  var j;
  for (j = 1; j <= 9; j++) {
    if (val["list" + j] == true || val["list" + j] == false) {
      obj.Elements.push({
        Id: 0,
        ReviewId: 0,
        ReviewElement: j.toString(),
        ReviewStatus: val["list" + j],
        Remarks: val["remark" + j] ?? "",
      });
    }
  }

  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;
    return axios
      .post(`${Action.LOCALHOST}api/ProjectReview/Create`, obj)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(
            getReviewFormTableData(
              projectGotById !== undefined ? projectGotById.IdProjet : 0
            )
          );
          dispatch(Actions.commonUpload("Review", res.data.Payload));
          dispatch(Actions.GetHeaderByProjectId(val.IdProjet));
          dispatch(Actions.GetHeaderByProjectId(obj.RevueDeProjet.IdProjet));
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.DataSuccess" />)
          );
          return true;
        } else {
          dispatch(
            Actions.fetchError(null, <IntlMessages id="Common.DataSaveError" />)
          );
          return false;
        }
      })
      .catch((err) => {
        dispatch(
          Actions.fetchError(err.message, <IntlMessages id="Common.DataFail" />)
        );
        return false;
      });
  };
};
export const UpdateReviewForm = (val) => {
  let obj = {
    RevueDeProjet: {
      Id: val.ReviewId,
      IdProjet: 0,
      Progress: val.progress,
      RemarksGeneral: val.remarkgen,
      ReviewNumber: val.reviewNumber,
    },
    Participants: [],
    Elements: [],
  };
  var i;
  for (i = 1; i <= 5; i++) {
    if (val["participant" + i]) {
      obj.Participants.push({
        Id: parseInt(val["participantId" + i] ?? 0),
        ReviewId: val.ReviewId,
        ParticipantEmployeeId: parseInt(val["participant" + i]),
        Discipline: val["discipline" + i] ?? "",
      });
    }
  }
  var j;
  for (j = 1; j <= 9; j++) {
    if (val["list" + j] == true || val["list" + j] == false) {
      obj.Elements.push({
        Id: parseInt(val["elementId" + j] ?? 0),
        ReviewId: val.ReviewId,
        ReviewElement: j.toString(),
        ReviewStatus: val["list" + j],
        Remarks: val["remark" + j] ?? "",
      });
    }
  }

  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;
    return axios
      .put(`${Action.LOCALHOST}api/ProjectReview/Update`, obj)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(Actions.commonUpload("Review", res.data.Payload));
          dispatch(Actions.GetHeaderByProjectId(val.IdProjet));
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.DataSuccess" />)
          );
          return true;
        } else {
          dispatch(
            Actions.fetchError(null, <IntlMessages id="Common.DataSaveError" />)
          );
          return false;
        }
      })
      .catch((err) => {
        dispatch(
          Actions.fetchError(err.message, <IntlMessages id="Common.DataFail" />)
        );
        return false;
      });
  };
};
export const resetReviewForm = () => {
  return {
    type: Action.RESET_REVIEWFORM_FORM,
  };
};

export const deleteProjectReview = (id) => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;

    return axios
      .delete(`${Action.LOCALHOST}api/ProjectReview/${id}`)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(
            Actions.GetHeaderByProjectId(
              projectGotById !== undefined ? projectGotById.IdProjet : 0
            )
          );
          return dispatch(
            getReviewFormTableData(
              projectGotById !== undefined ? projectGotById.IdProjet : 0
            )
          );
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        console.log(err);
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
