import { combineReducers } from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import CreateProject from "./CreateProject";
import Planification from "./Planification";
import RiskAnalysis from "./RiskAnalysis";
import Transactions from "./Transactions";
import ListByCharge from "./ListByCharge";
import ListByCustomer from "./ListByCustomer";
import FullList from "./FullList";
import Aprrovals from "./Approval";
import MyApproval from "./MyApproval";
import ProjectShort from "./ProjectShort";
import Accounts from "./Account";
import ProjectPhase from "./ProjectPhase";
import PhaseOffer from "./PhaseOffer";
import Avenant from "./Avenant";
import Surveillance from "./PlanificationSurveillance";
import Uploads from "./Upload";
import { connectRouter } from "connected-react-router";
import Accounting from "./Accounting";
import ReviewForm from "./ReviewForm";
import PostMortem from "./PostMortem";
import Opportunity from "./Opportunity";
import ListByPhaseOffer from "./ListByPhaseOffer";
import Bidding from "./Bidding";
import NewPostMortem from "./NewPostMortem";
import ContractReview from "./ContractReview";
import Project from "./Project";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    commonData: Common,
    createProject: CreateProject,
    ListByCharge,
    ListByCustomer,
    FullList,
    Aprrovals,
    MyApproval,
    ProjectShort,
    Accounts,
    ProjectPhase,
    PhaseOffer,
    Avenant,
    Planification,
    RiskAnalysis: RiskAnalysis,
    PostMortem,
    Surveillance,
    Transactions,
    Uploads,
    Accounting,
    ReviewForm,
    Opportunity,
    ListByPhaseOffer,
    Bidding,
    NewPostMortem,
    ContractReview,
    Project,
  });
