import * as Action from "../../constants/ActionTypes";
import * as ActionRedux from "../../appRedux/actions";
import * as Actions from "../../appRedux/actions";
import axios from "util/Api";
import React from "react";
import IntlMessages from "util/IntlMessages";
export const getFullList = (
  page,
  size,
  projectNumber = "",
  daveOpening = "",
  daveClosing = "",
  responsible = "",
  SearchKey = "",
  SortBy = "",
  StatutProjet = "",
  Department = "",
  Approvestatus = "",
  SortOrder = "",
  ClientNom = ""
) => {
  let locale = JSON.parse(localStorage.getItem("selectedlanguage"));
  console.log("Pagging", page, size);
  return (dispatch) => {
    dispatch(ActionRedux.GetAllLookupsByLanguage());
    return axios
      .post(
        `${Action.LOCALHOST}api/Project/GetListComplete?PageNumber=1&language=${locale?.locale}`,
        {
          // sortBy: "CreeLe",
          // sortOrder: false,
          pageNumber: page || 1,
          pageSize: size || 10,
          searchValue: "",
          projectNumber: projectNumber,
          daveOpening: daveOpening,
          daveClosing: daveClosing,
          responsible: responsible,
          SearchKey: SearchKey,
          SortBy: SortBy,
          DepartmentId: Department,
          StatutProjet: StatutProjet,
          Approvestatus: Approvestatus,
          SortOrder: SortOrder,
          ClientNom: ClientNom,
        }
      )
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_FULL_LIST,
            payload: res.data,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const deleteFullListProject = (id, page, size) => {
  return (dispatch) => {
    return axios
      .delete(`${Action.LOCALHOST}api/Project/${id}`)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(getFullList(page, size));
          dispatch(
            Actions.fetchSuccess(
              <IntlMessages id="FullList.ProjectDeletedSuccessfully" />
            )
          );
        } else {
          console.log(res);
          //return dispatch({
          //   type: Action.SHOW_MESSAGE,
          //   payload: <IntlMessages id="Common.DataFetchError" />,
          // });
          dispatch(
            Actions.fetchError(
              <IntlMessages id="FullList.ProjectDeleteFailureMessage" />,
              <IntlMessages id="FullList.ProjectDeleteFailure" />
            )
          );
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const downloadProjectFileShortcut = (id) => {
  return (dispatch) => {
    dispatch(ActionRedux.handleDownloadShortCut(id));
  };
};
