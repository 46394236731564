import * as Action from "../../constants/ActionTypes";
const init = {
  isParent: true,
  data: {},
  avenant: [],
  avenantTable: [],
  nextPhaseNumber: null,
  nextSubPhaseNumber: null,
};
const reducer = (state = init, action) => {
  switch (action.type) {
    case Action.ADD_PHASE_OFFER_AVENANT: {
      let arr =
        action.payload.IdPhaseProjet == 0
          ? state.avenant
          : state[action.payload.IdPhaseProjet].avenant || [];
      let obj = {
        totalFees: null,
        totalExpenses: null,
        totalsubcontractors: null,
        totalHours: null,
        totalEquipments: null,
        totalTests: null,
      };
      let phaseData =
        action.payload.IdPhaseProjet == 0
          ? {}
          : state[action.payload.IdPhaseProjet].data;
      let index = arr.findIndex(
        (x) => x.IdAvenant === action.payload.IdAvenant
      );
      index === -1 ? arr.push(action.payload) : (arr[index] = action.payload);

      arr.map((v) => {
        if (v.Active) {
          obj.totalFees = obj.totalFees + v.Fees;
          obj.totalExpenses = obj.totalExpenses + v.Spending;
          obj.totalsubcontractors = obj.totalsubcontractors + v.Subcontractor;
          obj.totalHours = obj.totalHours + v.Hours;
          obj.totalEquipments = obj.totalEquipments + v.Equipments;
          obj.totalTests = obj.totalTests + v.Tests;
        }
      });
      phaseData.TotalAventantsHonoraires = obj.totalFees;
      phaseData.TotalAventantsDepenses = obj.totalExpenses;
      phaseData.TotalAventantsSousTraitants = obj.totalsubcontractors;
      phaseData.TotalAventantsHeures = obj.totalHours;
      phaseData.TotalAventantsEquipments = obj.totalEquipments;
      phaseData.TotalAventantsTests = obj.totalTests;

      if (action.payload.IdPhaseProjet && action.payload.IdPhaseProjet > 0) {
        return {
          ...state,
          [action.payload.IdPhaseProjet]: {
            ...state[action.payload.IdPhaseProjet],
            avenant: arr,
            data: phaseData,
          },
        };
      } else {
        return {
          ...state,
          avenant: arr,
          data: phaseData,
        };
      }
    }
    case Action.ADD_EMPLOYEE_RATES_PHASE_OFFER: {
      return {
        ...state,
        [action.payload[0].PhaseProjetId]: {
          ...state[action.payload[0].PhaseProjetId],
          employeeRates: action.payload,
        },
      };
    }
    case Action.ADD_RATE_BY_EMPLOYEE_CLASS_PHASE_OFFER: {
      return {
        ...state,
        [action.payload[0].PhaseProjetId]: {
          ...state[action.payload[0].PhaseProjetId],
          rateByEmployeeClass: action.payload,
        },
      };
    }
    case Action.ADD_EQUIPMENTS_PHASE: {
      return {
        ...state,
        [action.payload.phaseId]: {
          ...state[action.payload.phaseId],
          data: action.payload.data,
        },
      };
    }
    case Action.ADD_LAB_TEST_PHASE: {
      return {
        ...state,
        [action.payload.phaseId]: {
          ...state[action.payload.phaseId],
          data: action.payload.data,
        },
      };
    }
    case Action.GET_PROJECT_PHASE: {
      return {
        ...state,
        // table: action.payload,
        [action.payload.id]: {
          ...state[action.payload.id],
          table: action.payload.data,
        },
      };
    }
    case Action.GET_SUB_PROJECT_PHASE_BY_ID: {
      return {
        ...state,
        subPhaseData: action.payload,
      };
    }
    case Action.GET_SUB_PROJECT_PHASES: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          table: action.payload.data,
        },
        // subPhases: action.payload,
      };
    }
    case Action.ADD_AVENANT_TABLE_PHASE_OFFER: {
      let idPhaseProjet = action.payload[0].IdPhaseProjet;
      return {
        ...state,
        [idPhaseProjet]: {
          ...state[idPhaseProjet],
          avenant: action.payload,
        },
      };
    }
    case Action.SET_AVENANT_TABLE_PHASE_OFFER: {
      // let arr = state.isParent ? state.avenantTable : state.Sub_avenantTable;
      let idPhaseProjet = 0;
      if (Array.isArray(action.payload)) {
        idPhaseProjet =
          action.payload[0][Object.keys(action.payload[0])[0]][0].IdPhaseProjet;
      } else {
        idPhaseProjet = action.payload.IdPhaseProjet;
      }

      let arr =
        (state[idPhaseProjet] && state[idPhaseProjet].avenantTable) ||
        state.avenantTable;
      if (Array.isArray(action.payload)) {
        arr = action.payload;
      } else {
        var index = arr.findIndex(
          (it) => Object.keys(it)[0] === action.payload.AvenantName
        );
        if (index === -1) {
          arr.push({ [action.payload.AvenantName]: [action.payload] });
        } else {
          arr[index] = { [action.payload.AvenantName]: [action.payload] };
        }
      }

      if (idPhaseProjet && idPhaseProjet > 0) {
        return {
          ...state,
          [idPhaseProjet]: {
            ...state[idPhaseProjet],
            avenantTable: arr,
          },
        };
      } else {
        return {
          ...state,
          avenantTable: arr,
        };
      }
    }
    case Action.DELETE_AVENANT_PHASE_OFFER: {
      let idPhase = action.payload.IdPhaseProjet;
      let arr = idPhase > 0 ? state[idPhase].avenant : state.avenant;
      let obj = {
        totalFees: null,
        totalExpenses: null,
        totalsubcontractors: null,
        totalHours: null,
        totalEquipments: null,
        totalTests: null,
      };

      let phaseData = idPhase ? state[idPhase].data : state.data;
      arr.map((v) => {
        if (v === action.payload) {
          v.Active = false;
        } else {
          if (v.Active) {
            obj.totalFees = obj.totalFees + v.Fees;
            obj.totalExpenses = obj.totalExpenses + v.Spending;
            obj.totalsubcontractors = obj.totalsubcontractors + v.Subcontractor;
            obj.totalHours = obj.totalHours + v.Hours;
            obj.totalEquipments = obj.totalEquipments + v.Equipments;
            obj.totalTests = obj.totalTests + v.Tests;
          }
        }
      });
      phaseData.TotalAventantsHonoraires = obj.totalFees;
      phaseData.TotalAventantsDepenses = obj.totalExpenses;
      phaseData.TotalAventantsSousTraitants = obj.totalsubcontractors;
      phaseData.TotalAventantsHeures = obj.totalHours;
      phaseData.TotalAventantsEquipments = obj.totalEquipments;
      phaseData.TotalAventantsTests = obj.totalTests;

      if (idPhase > 0) {
        return {
          ...state,
          [idPhase]: {
            ...state[idPhase],
            avanent: arr,
          },
        };
      } else {
        return {
          ...state,
          avanent: arr,
        };
      }
    }
    case Action.REPLACE_PHASE_ATTACHEMENT: {
      return {
        ...state,
        [action.name]: action.payload,
      };
    }
    case Action.RESET_FORM_PHASE: {
      return {
        ...state,
        //[action.payload]: init,
        avenant: [],
        avenantTable: [],
        data: {},
      };
    }
    case Action.DYNAMIC_PHASE_KEY: {
      let arr = state[action.name];
      if (arr === null || arr === undefined) {
        arr = [];
      }
      arr.push(action.payload);
      return {
        ...state,
        [action.name]: arr,
      };
    }
    case Action.SET_PROJECT_PHASE_BY_ID: {
      return {
        ...state,
        // data: action.payload,
        [action.payload.id]: {
          ...state[action.payload.id],
          data: action.payload.data,
        },
      };
    }
    case Action.SET_PHASE_MODE: {
      return {
        ...state,
        isParent: action.payload,
      };
    }
    case Action.SET_CREATE_PHASE_WAIT: {
      return {
        ...state,
        waitSavePhase: action.payload,
      };
    }
    case Action.SET_CREATE_PHASE_SUCCESS: {
      return {
        ...state,
        savePhaseSuccess: action.payload,
      };
    }
    case Action.NEXT_PHASE_NUMBER: {
      return {
        ...state,
        nextPhaseNumber: action.payload,
      };
    }
    case Action.NEXT_SUBPHASE_NUMBER: {
      return {
        ...state,
        nextSubPhaseNumber: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
