import * as Action from "../../constants/ActionTypes";
import _ from "lodash";
import axios from "util/Api";
import * as Actions from "./index";
import React from "react";
import IntlMessages from "util/IntlMessages";
import * as ActionProject from "../../appRedux/actions";
export const GetInvoiceDetails = (values) => async (dispatch) => {
  return axios
    .get(`${Action.LOCALHOST}api/Lookup/GetByType?type=InvoiceHeading`)
    .then((invoiceDetails) => {
      return dispatch({
        type: Action.GET_INVOICE_LIST,
        payload: invoiceDetails.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: Action.FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};

export const GetProjectAccountingDetails = (projectId) => (dispatch) => {
  dispatch(Actions.GetAllLookupsByLanguage());
  dispatch(Actions.getModifiedFields(projectId, "ProjectAccounting"));
  return axios
    .get(`${Action.LOCALHOST}api/ProjectAccounting/GetByProjetId/${projectId}`)
    .then((ProjectAccounting) => {
      return dispatch({
        type: Action.GET_PROJETACCOUNTING_BY_PROJETID,
        payload: ProjectAccounting.data.Payload,
      });
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: Action.FETCH_ERROR,
        payload: errors,
      });

      console.log("Error****:", errors);
    });
};

export const saveProjectAccounting = (values) => async (dispatch) => {
  try {
    const obj = {
      ...values,
      InstructionsParticuliereFeuillesDeTemps:
        values.InstructionsParticuliereFeuillesDeTemps === true ? 1 : null,
      InstructionsParticulierePieceJustificatives:
        values.InstructionsParticulierePieceJustificatives === true ? 1 : null,
      InstructionsParticuliereFactureSommaire:
        values.InstructionsParticuliereFactureSommaire === true ? 1 : null,
      InstructionsParticuliereAutres:
        values.InstructionsParticuliereAutres === true ? 1 : null,
      InstructionsParticuliereFactureParPhase:
        values.InstructionsParticuliereFactureParPhase === true ? 1 : null,
      InstructionsParticuliereMentionSpeciale:
        values.InstructionsParticuliereMentionSpeciale === true ? 1 : null,
      LitigationPossibilities:
        values.LitigationPossibilities === true ? 1 : null,
    };

    //     return axios
    //       .post(`${Action.LOCALHOST}api/ProjectAccounting/Create`, obj)
    //       .then((res) => {
    //         if (res.data.Payload) {
    //           dispatch(
    //             Actions.fetchSuccess("Project Accounting saved Successfully")
    //           );
    //           Promise.all([
    //             dispatch(
    //               Actions.commonUpload("Project", parseInt(values.ProjetId))
    //             ),
    //           ]).then(() => {});
    //         } else {
    //           dispatch(
    //             Actions.fetchError(
    //               res.data.ErrorMessage,
    //               "Project Accounting saved Failed"
    //             )
    //           );
    //         }
    //       })
    //       .catch((error) => {
    //         dispatch(
    //           Actions.fetchError(error.message, "Project Accounting saved Failed")
    //         );
    //       });
    //   } catch (err) {
    //     dispatch(
    //       Actions.fetchError(err.message, "Project Accounting saved Failed!")
    //     );
    //   }
    // };

    const res = await axios.post(
      `${Action.LOCALHOST}api/ProjectAccounting/Create`,
      obj
    );

    if (res.data.IsSuccess) {
      dispatch(Actions.fetchSuccess(<IntlMessages id="Accounting.Success" />));
      // Promise.all([
      dispatch(Actions.commonUpload("Project", parseInt(values.ProjetId)));
      // ]).then(() => {});
      return { success: true, id: res.data.Payload };
    } else {
      dispatch(
        Actions.fetchError(
          res.data.ErrorMessage,
          <IntlMessages id="Accounting.Failed" />
        )
      );
      return { success: false, id: null };
    }
  } catch (err) {
    dispatch(
      Actions.fetchError(err.message, <IntlMessages id="Accounting.Failed" />)
    );
    return { success: false, id: null };
  }
};

export const UpdateProjectAccounting = (values) => async (dispatch) => {
  try {
    const obj = {
      ...values,
      InstructionsParticuliereFeuillesDeTemps:
        values.InstructionsParticuliereFeuillesDeTemps === true ? 1 : null,
      InstructionsParticulierePieceJustificatives:
        values.InstructionsParticulierePieceJustificatives === true ? 1 : null,
      InstructionsParticuliereFactureSommaire:
        values.InstructionsParticuliereFactureSommaire === true ? 1 : null,
      InstructionsParticuliereAutres:
        values.InstructionsParticuliereAutres === true ? 1 : null,
      InstructionsParticuliereFactureParPhase:
        values.InstructionsParticuliereFactureParPhase === true ? 1 : null,
      InstructionsParticuliereMentionSpeciale:
        values.InstructionsParticuliereMentionSpeciale === true ? 1 : null,
      LitigationPossibilities:
        values.LitigationPossibilities === true ? 1 : null,
    };

    return axios
      .post(`${Action.LOCALHOST}api/ProjectAccounting/Update`, obj)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(Actions.fetchSuccess(<IntlMessages id="Common.Update" />));
          Promise.all([
            dispatch(
              Actions.commonUpload("Project", parseInt(values.ProjetId))
            ),
          ]).then(() => {});
        } else {
          dispatch(
            Actions.fetchError(
              res.data.ErrorMessage,
              <IntlMessages id="Common.UpdateError" />
            )
          );
        }
      })
      .catch((error) => {
        dispatch(
          Actions.fetchError(
            error.message,
            <IntlMessages id="Common.UpdateError" />
          )
        );
      });
  } catch (err) {
    dispatch(
      Actions.fetchError(err.message, <IntlMessages id="Common.UpdateError" />)
    );
  }
};

export const addProjectAccountingEmployeeRates = (payload) => {
  return {
    type: Action.ADD_EMPLOYEE_RATES_PROJECT_ACCOUNTING,
    payload,
  };
};
export const addProjectAccountingRateByEmployeeClass = (payload) => {
  return {
    type: Action.ADD_RATE_BY_EMPLOYEE_CLASS_PROJECT_ACCOUNTING,
    payload,
  };
};
export const ApproveAllByProject = (values) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${Action.LOCALHOST}api/ProjectAccounting/ApproveAllFormsByProject`,
      values
    );

    if (res.data.IsSuccess) {
      dispatch(Actions.fetchSuccess("Approved all accountings"));

      return { success: true, id: res.data.Payload };
    } else {
      dispatch(Actions.fetchError(res.data.ErrorMessage, "Approving Failed"));
      return { success: false, id: null };
    }
  } catch (err) {}
};

export const UpdateBillingValidation = (projectId) => async (dispatch) => {
  try {
    await axios
      .post(
        `${Action.LOCALHOST}api/Project/UpdateBillingValidation/${projectId}`
      )
      .then((res) => {
        if (res?.data?.Payload) {
          dispatch(ActionProject.GetProjectById(projectId));
          dispatch(ActionProject.GetHeaderByProjectId(projectId));
          dispatch(
            Actions.fetchSuccess(
              <IntlMessages id="Accounting.BillingDataValidated" />
            )
          );
        }
      })
      .catch((error) => {
        dispatch(
          Actions.fetchError(
            <IntlMessages id="Accounting.ErrorBillingDataValidated" />,
            <IntlMessages id="Common.UpdateError" />
          )
        );
      });
  } catch (err) {
    dispatch(
      Actions.fetchError(
        <IntlMessages id="Accounting.ErrorBillingDataValidated" />,
        <IntlMessages id="Common.UpdateError" />
      )
    );
  }
};

export const GetReserveProjectAccountingBillingApi = () => async (dispatch) => {
  try {
    await axios
      .get(
        `${Action.LOCALHOST}api/Project/GetAllReservedProjectsAccountingBilling`
      )
      .then((res) => {
        dispatch({
          type: Action.GET_RESERVEDPROJECTACCOUNTING_LIST,
          payload: res.data.Payload,
        });
      })
      .catch((error) => {
        dispatch(
          Actions.fetchError(
            <IntlMessages id="Accounting.ErrorToFetchReserveProject" />,
            <IntlMessages id="Common.UpdateError" />
          )
        );
      });
  } catch (err) {
    dispatch(
      Actions.fetchError(
        <IntlMessages id="Accounting.ErrorToFetchReserveProject" />,
        <IntlMessages id="Common.UpdateError" />
      )
    );
  }
};

export const ReserveProjectAccountingBillingApi =
  (values) => async (dispatch) => {
    try {
      await axios
        .post(
          `${Action.LOCALHOST}api/Project/ReserveProjectAccountingBilling`,
          values
        )
        .then((res) => {
          if (res?.data?.Payload) {
            dispatch(GetReserveProjectAccountingBillingApi());
            dispatch(
              Actions.fetchSuccess(
                <IntlMessages id="Accounting.ProjectReserveSuccess" />
              )
            );
          }
        })
        .catch((error) => {
          dispatch(
            Actions.fetchError(
              <IntlMessages id="Accounting.ErrorReserveProject" />,
              <IntlMessages id="Common.UpdateError" />
            )
          );
        });
    } catch (err) {
      dispatch(
        Actions.fetchError(
          <IntlMessages id="Accounting.ErrorReserveProject" />,
          <IntlMessages id="Common.UpdateError" />
        )
      );
    }
  };
