import { act } from "react-dom/test-utils";
import * as Action from "../../constants/ActionTypes";
const initState = {
  MasterData: {},
  TableData: {},
  BiddingById: null,
  DistributionRecords: [],
};
export const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.GET_BIDDING_MASTERDATA: {
      return {
        ...state,
        MasterData: action.payload,
        BiddingVP: action.payload.BiddingVP,
        BiddingManpowerClass: action.payload.BiddingManpowerClass,
        BiddingManpowerRate: action.payload.BiddingManpowerRate,
        BiddingDefaultMajoration: action.payload.BiddingDefaultMajoration,
        BiddingEquipment: action.payload.BiddingEquiment,
        BiddingExpense: action.payload.BiddingExpense,
        BiddingLabTest: action.payload.BiddingLabTest,
        ExpenseDropdown: action.payload.ExpenseDropdown,
      };
    }
    case Action.SET_BIDDING_QUOTE_TABLE_DATA: {
      return {
        ...state,
        TableData: action.payload,
      };
    }
    case Action.SET_BIDDING_BY_ID: {
      return {
        ...state,
        BiddingById: action.payload,
      };
    }

    case Action.GET_DISTRIBUTION_DETAILS: {
      return {
        ...state,
        DistributionRecords: action.payload,
      };
    }
    case Action.RESET_BIDDING_BY_ID: {
      return {
        ...state,
        BiddingById: null,
      };
    }
    case Action.GET_BILLING_METHOD_LAB: {
      return {
        ...state,
        BillingMethodLookup: action.payload,
      };
    }
    case Action.GET_LIST_TRAILS: {
      return {
        ...state,
        TrailsList: action.payload,
      };
    }
    case Action.GET_LIST_EQUIPMENT_VEHICLES: {
      return {
        ...state,
        EuipmentTrailsList: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
