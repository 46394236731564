import * as Action from "../../constants/ActionTypes";
import * as Actions from "./index";
import axios from "util/Api";
import { batch } from "react-redux";
import React from "react";
import IntlMessages from "util/IntlMessages";
export const getPhaseProject = (id) => {
  let locale = JSON.parse(localStorage.getItem("selectedlanguage"));
  return (dispatch, getState) => {
    return axios
      .get(
        `${Action.LOCALHOST}api/PhaseProject/Project/${id}/${locale?.locale}`
      )
      .then((res) => {
        console.log("ID", id, res);
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_PROJECT_PHASE,
            payload: { id: id, data: res.data.Payload },
          });
        } else {
          dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const getChildPhaseProjects = (id) => {
  let locale = JSON.parse(localStorage.getItem("selectedlanguage"));

  return (dispatch, getState) => {
    return axios
      .get(
        `${Action.LOCALHOST}api/PhaseProject/ChildPhases/ProjectPhase/${id}/${locale?.locale}`
      )
      .then((res) => {
        console.log("ID", id, res);
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_SUB_PROJECT_PHASES,
            payload: { id: id, data: res.data.Payload },
          });
        } else {
          dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const dynamicKeySet = (name, value) => {
  return {
    type: Action.DYNAMIC_PHASE_KEY,
    payload: value,
    name,
  };
};
export const updateAttachment = (name, value) => {
  return {
    type: Action.REPLACE_PHASE_ATTACHEMENT,
    payload: value,
    name,
  };
};
export const getPhaseProjectById = (id, isParent) => {
  return (dispatch, getState) => {
    return axios
      .get(`${Action.LOCALHOST}api/PhaseProject/${id}`)
      .then((res) => {
        if (res.data.Payload) {
          let attachement = res.data.Payload.lstAttachment;
          let obj = [];
          res.data.Payload.GroupedPhaseProjectAvenant &&
            res.data.Payload.GroupedPhaseProjectAvenant.map((v) =>
              v[Object.keys(v)[0]].map((val) => obj.push(val))
            );
          batch(() => {
            dispatch(Actions.resetWholeFormInDragger("ProjectPhase"));
            if (
              res.data.Payload.GroupedPhaseProjectAvenant &&
              res.data.Payload.GroupedPhaseProjectAvenant.length > 0
            ) {
              dispatch(
                addAvenantTable(res.data.Payload.GroupedPhaseProjectAvenant)
              );
            }
            Promise.all([
              dispatch(Actions.handleGetUploads(attachement, "ProjectPhase")),
            ]);
            if (obj.length > 0) {
              Promise.all([dispatch(addAvenantTables(obj))]);
            }
          });

          return dispatch({
            type: Action.SET_PROJECT_PHASE_BY_ID,
            payload: { id: id, data: res.data.Payload },
          });
        } else {
          dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const copyPhaseProject = (id) => {
  return (dispatch, getState) => {
    return axios
      .post(`${Action.LOCALHOST}api/PhaseProject/CopyPhaseProject/${id}`, null)
      .then((res) => {
        if (res.data.Payload) {
          return parseInt(res.data.Payload);
        } else {
          dispatch(Actions.resetWholeFormInDragger("ProjectPhase"));
          dispatch(
            Actions.fetchError(
              "Saved Failed",
              <IntlMessages id="Phase.NotSuccess" />
            )
          );
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const addAvenantTables = (payload) => {
  return {
    type: Action.ADD_AVENANT_TABLE_PHASE_OFFER,
    payload,
  };
};
export const addAvenant = (payload) => {
  return {
    type: Action.ADD_PHASE_OFFER_AVENANT,
    payload,
  };
};

export const addEmployeeRates = (payload) => {
  return {
    type: Action.ADD_EMPLOYEE_RATES_PHASE_OFFER,
    payload,
  };
};
export const addRateByEmployeeClass = (payload) => {
  return {
    type: Action.ADD_RATE_BY_EMPLOYEE_CLASS_PHASE_OFFER,
    payload,
  };
};
export const addPhaseProjectEquipments = (payload) => {
  return {
    type: Action.ADD_EQUIPMENTS_PHASE,
    payload,
  };
};
export const addPhaseProjectLabTests = (payload) => {
  return {
    type: Action.ADD_LAB_TEST_PHASE,
    payload,
  };
};
export const addAvenantTable = (payload) => {
  return {
    type: Action.SET_AVENANT_TABLE_PHASE_OFFER,
    payload,
  };
};

export const deleteAvenantData = (payload) => {
  return {
    type: Action.DELETE_AVENANT_PHASE_OFFER,
    payload,
  };
};

export const resetPhaseProject = (payload) => {
  return {
    type: Action.RESET_FORM_PHASE,
    payload,
  };
};

export const setProjectPhaseMode = (payload) => {
  return {
    type: Action.SET_PHASE_MODE,
    payload,
  };
};

export const setCreatePhaseWaiting = (payload) => {
  return {
    type: Action.SET_CREATE_PHASE_WAIT,
    payload,
  };
};

export const setCreatePhaseSuccess = (payload) => {
  return {
    type: Action.SET_CREATE_PHASE_SUCCESS,
    payload,
  };
};

export const savePhaseOffer = (data) => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;
    const { avenant } = getState().ProjectPhase;

    const val = getState().ProjectPhase;
    let obj = {
      ...data,
      NoProjet: projectGotById.NoProjet,
      SurDemandeDuChargeDeProjet:
        data.SurDemandeDuChargeDeProjet === true ? true : false,
      Mensuel: data.Mensuel === true ? true : false,
      ProgressionDuMandat: data.ProgressionDuMandat === true ? true : false,
      Forfaitaire: data.Forfaitaire === true ? true : false,
      Horaire: data.Horaire === true ? true : false,
      AuDesCoutsDeProjet: data.AuDesCoutsDeProjet === true ? true : false,
      Francais: data.Francais === true ? true : false,
      Anglais: data.Anglais === true ? true : false,
      IdProjet: projectGotById !== undefined ? projectGotById.IdProjet : 0,
      PhaseProjectAvenant: avenant,
      EtatPhase: data.EtatPhase === "Active" ? "68" : data.EtatPhase,
      // DateFin: data.DateFin.format("YYYY-MM-DD"),
      //DateDebut: data.DateDebut.format("YYYY-MM-DD"),
    };
    return axios
      .post(`${Action.LOCALHOST}api/PhaseProject/Create`, obj)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(Actions.fetchSuccess(<IntlMessages id="Phase.Update" />));
          Promise.all([
            dispatch(
              Actions.commonUpload("ProjectPhase", parseInt(res.data.Payload))
            ),
            dispatch(Actions.GetHeaderByProjectId(obj.IdProjet)),
          ]).then(() => {
            dispatch(getPhaseProject(projectGotById.IdProjet));
            dispatch(getPhaseProjectById(parseInt(res.data.Payload)));
            if (obj.IdParentPhaseProjet && obj.IdParentPhaseProjet != 0) {
              dispatch(getChildPhaseProjects(obj.IdParentPhaseProjet));
              dispatch(getAvailableProjectSubPhaseNo(obj.IdParentPhaseProjet));
            }
          });
          return res.data.Payload;
        } else if (res.data.StatusCode == 100) {
          dispatch(
            Actions.fetchError(
              <IntlMessages id="ProjectPhases.Modal.Heading.NoPhaseValidation" />,
              "Saved Failed"
            )
          );
        } else {
          dispatch(
            Actions.fetchError(
              "Saved Failed",
              <IntlMessages id="Phase.NotSuccess" />
            )
          );
          return false;
        }
      })
      .catch((err) => {
        dispatch(
          Actions.fetchError(
            err.message,
            <IntlMessages id="Phase.NotSuccess" />
          )
        );
        return false;
      });
  };
};
export const UpdateProjectPhase = (val) => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;
    const { data } = getState().ProjectPhase[val.IdPhaseProjet];
    const { avenantTable } = getState().ProjectPhase[val.IdPhaseProjet];
    const { avenant } = getState().ProjectPhase[val.IdPhaseProjet];

    // const { subPhaseData } = getState().ProjectPhase;
    // const { Sub_avenantTable } = getState().ProjectPhase;
    // const { Sub_avenant } = getState().ProjectPhase;
    const { isParent } = getState().ProjectPhase;
    let obj = {
      ...val,
      IdProjet: projectGotById !== undefined ? projectGotById.IdProjet : 0,
      NoProjet: projectGotById.NoProjet,
      IdPhaseProjet: data !== undefined ? data.IdPhaseProjet : 0,
      GroupedPhaseProjectAvenant: avenantTable,
      phaseProjectAvenant: avenant,
    };
    const authdata = getState().auth;
    return axios
      .put(`${Action.LOCALHOST}api/PhaseProject/Update`, obj)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(Actions.fetchSuccess(<IntlMessages id="Phase.Update" />));
          dispatch(Actions.commonUpload("ProjectPhase", res.data.Payload));
          if (
            (obj?.ApprouveStatus === 1 || obj?.ApprouveStatus === 2) &&
            projectGotById?.EmployeIdChargeProjet ===
              authdata?.authUser?.employeeid
          ) {
            dispatch(
              Actions.changeStatus(
                obj?.IdPhaseProjet,
                1,
                "PP",
                null,
                null,
                true
              )
            ).then(() => {});
          } else {
          }
          if (isParent) {
            dispatch(getPhaseProject(projectGotById.IdProjet));
          } else {
            dispatch(setProjectPhaseMode(true));
            dispatch(getChildPhaseProjects(data.IdParentPhaseProjet));
            dispatch(getPhaseProjectById(data.IdPhaseProjet));
          }
          dispatch(Actions.GetHeaderByProjectId(obj.IdProjet));
          if (
            obj.IdParentPhaseProjet &&
            obj.IdParentPhaseProjet != 0 &&
            projectGotById.IdProjet != obj.IdParentPhaseProjet
          ) {
            dispatch(getChildPhaseProjects(obj.IdParentPhaseProjet));
          }
          return true;
        } else {
          dispatch(
            Actions.fetchError(
              "Update Failed",
              <IntlMessages id="Phase.NotUpdate" />
            )
          );
          return false;
        }
      })
      .catch((err) => {
        dispatch(
          Actions.fetchError(err.message, <IntlMessages id="Phase.NotUpdate" />)
        );
        return false;
      });
  };
};

export const deletePhaseProject = (data) => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;

    return axios
      .delete(`${Action.LOCALHOST}api/PhaseProject/${data?.IdPhaseProjet}`)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(getPhaseProject(projectGotById.IdProjet));
          dispatch(Actions.GetHeaderByProjectId(projectGotById.IdProjet));
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.SuccessDelete" />)
          );
          if (data.IdParentPhaseProjet && data.IdParentPhaseProjet > 0) {
            dispatch(getChildPhaseProjects(parseInt(data.IdParentPhaseProjet)));
            dispatch(getAvailableProjectSubPhaseNo(data.IdParentPhaseProjet));
          }
        } else {
          dispatch(
            Actions.fetchError(
              "Not Deleted",
              <IntlMessages id="Common.FailDelete" />
            )
          );
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch(
          Actions.fetchError(errors, <IntlMessages id="Common.FailDelete" />)
        );
      });
  };
};

export const UpdateChildProjectPhaseStatus = (ids, status, IdProjet) => {
  return (dispatch) => {
    return axios
      .post(
        `${Action.LOCALHOST}api/PhaseProject/UpdateProjectPhaseStatus/${status}`,
        ids
      )
      .then((res) => {
        console.log(res);
        dispatch(getPhaseProject(IdProjet));
        // if (res.data.Payload) {
        //   dispatch(
        //     Actions.fetchSuccess(<IntlMessages id="Common.StatusChange" />)
        //   );
        // }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const CloseProjectPhase = (idProject) => {
  return (dispatch) => {
    return axios
      .post(
        `${Action.LOCALHOST}api/PhaseProject/CloseProjectPhase/${idProject}`
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const changeProjStatus = (id, status) => {
  return (dispatch) => {
    return axios
      .post(
        `${Action.LOCALHOST}api/Project/ChangeProjStatus?id=${id}&status=${status}`
      )
      .then((res) => {
        console.log(res);
        if (res.data.Payload) {
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.StatusChange" />)
          );
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const CheckDuplicatePhaseNumber = (
  phaseNo,
  projectId,
  phaseRefId,
  parentId
) => {
  return (dispatch, getState) => {
    return axios
      .get(
        `${Action.LOCALHOST}api/PhaseProject/CheckDuplicatePhaseNumber/${phaseNo}/${projectId}/${phaseRefId}/${parentId}`
      )
      .then((res) => {
        return res.data.Payload;
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const checkPhaseAccounted = (ref, area) => {
  return (dispatch, getState) => {
    return axios
      .get(`${Action.LOCALHOST}api/History/Archive/${ref}/${area}`)
      .then((res) => {
        var isAccounted = false;
        if (res.data.Payload && res.data.Payload.length > 0) {
          res.data.Payload.map((obj) => {
            if (!isAccounted) {
              if (obj.TypeAction === "ACCOUNTING") {
                isAccounted = true;
              }
            }
          });
        }
        return isAccounted;
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        console.log(err);
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const checkPhaseAccountedForPhaseNo = (ref, area) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/History/Archive/${ref}/${area}`)
      .then((res) => {
        var isAccounted = false;
        if (res.data.Payload && res.data.Payload.length > 0) {
          res.data.Payload.map((obj) => {
            if (!isAccounted) {
              if (
                obj.TypeAction === "ACCOUNTING" ||
                obj.TypeAction === "APPROVE"
              ) {
                isAccounted = true;
              }
            }
          });
        }
        return isAccounted;
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const updateProjectPhaseByGanttChart = (record) => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;

    return axios
      .post(
        `${Action.LOCALHOST}api/PhaseProject/UpdateProjectPhaseByGanttChart`,
        record
      )
      .then((res) => {
        if (res?.data?.Payload) {
          dispatch(getPhaseProject(projectGotById.IdProjet));
          dispatch(Actions.fetchSuccess(<IntlMessages id="Phase.Update" />));
        } else
          dispatch(
            Actions.fetchError(
              "Update Failed",
              <IntlMessages id="Phase.NotUpdate" />
            )
          );
        return res.data.Payload;
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const checkForProjectPhaseDisabled = (projectId) => {
  return (dispatch, getState) => {
    return axios
      .get(
        `${Action.LOCALHOST}api/PhaseProject/CheckForProjectPhaseDisability/${projectId}`
      )
      .then((res) => {
        // debugger;
        // if (res?.data?.Payload) {
        //   return res.data.Payload;
        // } else
        //   dispatch(
        //     Actions.fetchError(
        //       "Fethching error",
        //       <IntlMessages id="Phase.NotUpdate111" />
        //     )
        //   );
        return res.data.Payload;
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const createWorkProgramme = (data, setLoader, setDisabledNew) => {
  return (dispatch) => {
    setLoader(true);
    return axios
      .post(`${Action.LOCALHOST}api/PhaseProject/CreateWorkProgramme`, data)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(Actions.fetchSuccess(<IntlMessages id="Phase.Update" />));
          Promise.all([
            dispatch(
              Actions.commonUpload("ProjectPhase", parseInt(res.data.Payload))
            ),
            dispatch(Actions.GetHeaderByProjectId(data.IdProjet)),
          ]).then(() => {
            dispatch(getPhaseProject(data.IdProjet));
            dispatch(getPhaseProjectById(parseInt(res.data.Payload)));
            if (data.IdParentPhaseProjet && data.IdParentPhaseProjet != 0) {
              dispatch(getChildPhaseProjects(data.IdParentPhaseProjet));
            }
            setLoader(false);
          });
          return res.data.Payload;
        } else {
          dispatch(
            Actions.fetchError(
              "Saved Failed",
              <IntlMessages id="Phase.NotSuccess" />
            )
          );
          setLoader(false);
          setDisabledNew(true);
          return false;
        }
      })
      .catch((err) => {
        dispatch(
          Actions.fetchError(
            err.message,
            <IntlMessages id="Phase.NotSuccess" />
          )
        );
        setLoader(false);
        setDisabledNew(true);
        return false;
      });
  };
};

export const getProjectPhaseNo = (projectId) => {
  return (dispatch) => {
    return axios
      .get(
        `${Action.LOCALHOST}api/PhaseProject/GetAvailableProjectPhaseNo?projectId=${projectId}`
      )
      .then((res) => {
        return dispatch({
          type: Action.NEXT_PHASE_NUMBER,
          payload: res.data,
        });
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const getAvailableProjectSubPhaseNo = (parentId) => {
  return (dispatch) => {
    return axios
      .get(
        `${Action.LOCALHOST}api/PhaseProject/GetAvailableProjectSubPhaseNo?parentId=${parentId}`
      )
      .then((res) => {
        return dispatch({
          type: Action.NEXT_SUBPHASE_NUMBER,
          payload: res.data,
        });
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
