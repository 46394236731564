import {
  INIT_URL,
  SET_EMPLOYEE_DETAILS,
  SET_COMPANY_DETAILS,
  SET_COMPANY_REPRESENTATIVE_DETAILS,
  SET_COMPANY_REPRESENTATIVE_ADDRESS,
  SET_COMPANY_CLIENT_ADDRESS,
  SET_PROJECT_LIST,
  SET_PROJECT_BY_ID,
  SET_FORM_STATE,
  GENERIC_APP_FILE_UPLOAD_SET,
  SET_PROJECT_LOCATIONS,
  ADD_PROJECT_LOCATIONS,
  UPDATE_PROJECT_LOCATIONS,
  SET_ALL_LOCATIONS,
  UPLOAD_PUSH_IDENTIIFIER,
  SET_PROJECT_ID,
  GET_CLIENT_TYPES,
  GET_CURRENCY_DETAILS,
  GET_FORMTYPES_DETAILS,
  GET_EMPLOYEE_RATE,
  GET_HEADER_BY_PROJECT_ID,
  SET_AVAILABLE_SUFF_FOR_PROJECT,
  GET_MANDATEPROJECTS_LIST,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  AttachementInstructionsParticuliere: null,
  AttachementTermesContratClient: null,
  AttachementAutres: null,
  AttachementProjetPresicionsNecessaires: null,
  AttachementSousTraitants: null,
  AttachementProjetDoitEtreReviseOui: null,
  projectLocations: [],
  allLocations: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GENERIC_APP_FILE_UPLOAD_SET: {
      return {
        ...state,
        [action.payload.name]: action.payload.file,
      };
    }
    case SET_FORM_STATE: {
      return {
        ...state,
        form: action.payload,
      };
    }
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }
    case SET_EMPLOYEE_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_COMPANY_DETAILS: {
      return {
        ...state,
        companyDetails: action.payload,
      };
    }
    case SET_PROJECT_LOCATIONS: {
      return {
        ...state,
        projectLocations: action.payload,
      };
    }
    case SET_ALL_LOCATIONS: {
      return {
        ...state,
        allLocations: action.payload,
      };
    }
    case ADD_PROJECT_LOCATIONS: {
      return {
        ...state,
        projectLocations: [...state.projectLocations, action.payload],
      };
    }
    case UPDATE_PROJECT_LOCATIONS: {
      var locations = state.projectLocations;
      locations.map((item, index) => {
        if (item.uid == action.payload.uid) {
          if (!action.payload.IsDeleted) {
            item.Latitude = action.payload.Latitude;
            item.Longitude = action.payload.Longitude;
          }
          if (action.payload.IsDeleted)
            item.IsDeleted = action.payload.IsDeleted;
        }
      });
      return {
        ...state,
        projectLocations: locations,
      };
    }
    case SET_COMPANY_REPRESENTATIVE_DETAILS: {
      return {
        ...state,
        representativeDetails: action.payload,
      };
    }
    case SET_COMPANY_REPRESENTATIVE_ADDRESS: {
      return {
        ...state,
        representativeContactDetails: action.payload,
      };
    }
    case SET_COMPANY_CLIENT_ADDRESS: {
      return {
        ...state,
        ClientAddressDetails: action.payload,
      };
    }
    case GET_EMPLOYEE_RATE: {
      return {
        ...state,
        employeeRate: action.payload,
      };
    }
    case SET_PROJECT_LIST: {
      return {
        ...state,
        allProjectList: action.payload,
      };
    }
    case SET_PROJECT_ID: {
      return {
        ...state,
        projectGotById: {
          IdProjet: action.payload,
        },
      };
    }
    case SET_PROJECT_BY_ID: {
      return {
        ...state,
        projectGotById: action.payload,
      };
    }
    case GET_CLIENT_TYPES: {
      return {
        ...state,
        clientTypes: action.payload,
      };
    }
    case GET_CURRENCY_DETAILS: {
      return {
        ...state,
        currencyUnits: action.payload,
      };
    }
    case GET_FORMTYPES_DETAILS: {
      return {
        ...state,
        formTypes: action.payload,
      };
    }
    case UPLOAD_PUSH_IDENTIIFIER: {
      let arr = state[action.payload.name];
      console.log(arr);
      if (arr === null || arr === undefined) {
        arr = [];
      }
      arr.push(action.payload.file);
      return {
        ...state,
        [action.payload.name]: arr,
      };
    }
    case GET_HEADER_BY_PROJECT_ID: {
      return {
        ...state,
        headerDetails: action.payload,
      };
    }
    case SET_AVAILABLE_SUFF_FOR_PROJECT: {
      return {
        ...state,
        availableSuffForProject: action.payload,
      };
    }
    case GET_MANDATEPROJECTS_LIST: {
      return {
        ...state,
        mandateProjectList: action.payload,
      };
    }
    default:
      return state;
  }
};
