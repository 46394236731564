import * as Action from "../../constants/ActionTypes";
import axios from "util/Api";
import React from "react";
import IntlMessages from "util/IntlMessages";
export const getListByCustomer = (
  page,
  size,
  searchKey = "",
  projectNumber = "",
  daveOpening = "",
  daveClosing = "",
  responsible = "",
  ClientNom = ""
) => {
  return (dispatch) => {
    return axios
      .post(`${Action.LOCALHOST}api/Project/GetAllListByCustomers`, {
        pageNumber: page || 1,
        pageSize: size || 50,
        projectNumber: projectNumber,
        daveOpening: daveOpening,
        daveClosing: daveClosing,
        responsible: responsible,
        SearchKey: searchKey,
        ClientNom: ClientNom,
      })
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_LIST_BY_CUSTOMER,
            payload: res.data,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const runtimeKeyListByCutomer = (
  key,
  page,
  size,
  searchKey = "",
  projectNumber = "",
  daveOpening = "",
  daveClosing = "",
  responsible = "",
  ClientNom = ""
) => {
  return (dispatch) => {
    return axios
      .post(`${Action.LOCALHOST}api/Project/GetAllListByCustomersDetails`, {
        pageNumber: page || 1,
        pageSize: size || 50,
        searchValue: key,
        projectNumber: projectNumber,
        daveOpening: daveOpening,
        daveClosing: daveClosing,
        responsible: responsible,
        SearchKey: searchKey,
        ClientNom: ClientNom,
      })
      .then((res) => {
        const value = key.split(" ")[0];
        if (res.data.Payload) {
          return dispatch({
            type: Action.RUN_TIME_KEY_LIST_BY_CUSTOMER,
            payload: {
              data: res.data.Payload,
              key: value,
              page: res.data.TotalRecordCount,
            },
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        console.log(err);
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
