import enLang from "./entries/en-US";

import frLang from "./entries/fr_FR";

const AppLocale = {
  en: enLang,
  fr: frLang
};

export default AppLocale;
