import React from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { useHistory } from "react-router-dom";
const UserInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          dispatch(userSignOut());
          history.push("/");
        }}
      >
        Logout
      </li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar
        src={localStorage.getItem("profilePhoto")}
        className="gx-size-40 gx-pointer gx-mr-3"
        alt=""
      />
    </Popover>
  );
};

export default UserInfo;
