import * as Action from "../../constants/ActionTypes";
const initState = {
  Data: [],
  CorporateRiskDate: [],
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.GET_MYAPPROVAL_LIST: {
      return {
        ...state,
        Data: action.payload,
      };
    }
    case Action.GET_MYAPPROVAL_CORPORATERISK_LIST: {
      return {
        ...state,
        CorporateRiskDate: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default reducer;
