import * as Action from "../../constants/ActionTypes";
const initState = {
  TableData: [],
  AmendAttachment: [],
  ProposalDocument: [],
  EndorsementContractAttachment: [],
  EndorsementOtherAttachment: [],
  Reference1Attachment: [],
  Reference2Attachment: [],
  OtherGuidelinesAttachment: [],
  History: [],
  Transection: [],
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.GET_HISTORY_OF_AVENANT: {
      return {
        ...state,
        History: action.payload,
      };
    }
    case Action.GET_TRANSECTION_HISTORY_OF_AVENANT: {
      return {
        ...state,
        Transection: action.payload,
      };
    }
    case Action.SET_AVENANT_TABLE_DATA: {
      return {
        ...state,
        TableData: action.payload,
      };
    }
    case Action.UPLOAD_IDENTIFIER_BY_KEY_AVENANT: {
      let arr = state[action.name];
      if (arr === null || arr === undefined) {
        arr = [];
      }
      arr.push(action.payload);
      return {
        ...state,
        [action.name]: arr,
      };
    }
    case Action.UPDATE_IDENTIFIER_BY_KEY_AVENANT: {
      return {
        ...state,
        [action.name]: action.payload,
      };
    }
    case Action.GET_AVENANTS_BY_ID: {
      return {
        ...state,
        AvenantData: action.payload,
      };
    }
    case Action.RESET_AVENANT_FORM: {
      return {
        ...state,
        AmendAttachment: [],
        ProposalDocument: [],
        EndorsementContractAttachment: [],
        EndorsementOtherAttachment: [],
        Reference1Attachment: [],
        Reference2Attachment: [],
        OtherGuidelinesAttachment: [],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
