import * as Action from "../../constants/ActionTypes";
import _ from "lodash";
import axios from "util/Api";
import React from "react";
import IntlMessages from "util/IntlMessages";
import * as Actions from "./index";
import { batch } from "react-redux";
export const getMyApprovalsList = (employeeid) => {
  employeeid = employeeid || "";

  return (dispatch) => {
    dispatch(Actions.GetAllLookupsByLanguage());
    return axios
      .get(
        `${Action.LOCALHOST}api/Project/GetMyApprovals?employeeid=${employeeid}`
      )
      .then((res) => {
        console.log("My Approval List", res.data);
        if (res.data.Payload) {
          const payload = _.groupBy(res.data.Payload, function (o) {
            return o.Department;
          });
          return dispatch({
            type: Action.GET_MYAPPROVAL_LIST,
            payload: payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};

export const getMyApprovalsCorporaterisk = (employeeid) => {
  employeeid = employeeid || "";

  return (dispatch) => {
    dispatch(Actions.GetAllLookupsByLanguage());
    return axios
      .get(
        `${Action.LOCALHOST}api/Project/GetMyApprovalsCorporaterisk?employeeid=${employeeid}`
      )
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_MYAPPROVAL_CORPORATERISK_LIST,
            payload: res.data.Payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
