import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  HIDE_MESSAGE,
  LOCALHOST,
  SHOW_NO_ACCESS_MESSAGE,
} from "../../constants/ActionTypes";
import jwtDecode from "jwt-decode";
import axios from "util/Api";
import React from "react";
import IntlMessages from "util/IntlMessages";
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignUp = ({ email, password, name }) => {
  console.log(email, password);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/register", {
        email: email,
        password: password,
        name: name,
      })
      .then(({ data }) => {
        console.log("data:", data);
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["authorization"] =
            "bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          console.log("payload: data.error", data.error);
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id="Common.NetworkError" />,
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};
console.log("userSignIn: ");
export const userSignIn = ({ email, password }) => {
  return (dispatch) => {
    axios
      .post("/api/token", {
        userName: email,
        password: password,
        isPersistence: true,
      })
      .then(({ data }) => {
        if (data.Payload) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.Payload.access_token)
          );

          const decoded = jwtDecode(data.Payload.access_token);
          if (decoded) localStorage.setItem("user", JSON.stringify(decoded));

          if (data.Payload.profilePhoto)
            localStorage.setItem(
              "profilePhoto",
              `data:image/png;base64, ${data.Payload.profilePhoto}`
            );

          if (data.Payload.roles)
            localStorage.setItem(
              "userroles",
              JSON.stringify(data.Payload.roles)
            );

          axios.defaults.headers.common[
            "authorization"
          ] = `${data.Payload.token_type} ${data.Payload.access_token}`;
          dispatch({
            type: USER_TOKEN_SET,
            payload: data.Payload.access_token,
          });
          dispatch({ type: USER_DATA, payload: decoded });
        } else {
          if (data.StatusDesc === "NoAccess") {
            dispatch({
              type: SHOW_NO_ACCESS_MESSAGE,
              payload: data.ErrorMessage,
            });
          } else {
            dispatch({ type: FETCH_ERROR, payload: data.StatusDesc });
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/me")
      .then(({ data }) => {
        console.log("userSignIn: ", data);
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    let locale = localStorage.getItem("selectedlanguage");
    let fullList_SearchDetails = localStorage.getItem("FullList_SearchDetails");
    let listByCustomer_SearchDetails = localStorage.getItem(
      "ListByCustomer_SearchDetails"
    );
    let approvals_SearchDetails = localStorage.getItem(
      "Approvals_SearchDetails"
    );
    let accounting_SearchDetails = localStorage.getItem(
      "Accounting_SearchDetails"
    );
    localStorage.clear();
    localStorage.setItem("FullList_SearchDetails", fullList_SearchDetails);
    localStorage.setItem(
      "ListByCustomer_SearchDetails",
      listByCustomer_SearchDetails
    );
    localStorage.setItem("Approvals_SearchDetails", approvals_SearchDetails);
    localStorage.setItem("Accounting_SearchDetails", accounting_SearchDetails);
    localStorage.setItem("selectedlanguage", locale);

    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
  };
};
