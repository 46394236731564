import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import { useDispatch } from "react-redux";
import { userSignOut } from "appRedux/actions/Auth";
import { Modal } from "antd";
import { TimeOutMinutes } from "../../constants/ActionTypes";
import { useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";

function IdleTimerContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const idleTimerRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const sessionTimeoutRef = useRef(null);
  const [timeoutMin, setTimeoutMin] = useState(parseInt(TimeOutMinutes));
  const onIdle = () => {
    setModalIsOpen(true);
    //Force logout - Idle time after showing popup - 1 Min
    sessionTimeoutRef.current = setTimeout(logout, 60 * 1000);
  };

  const logout = () => {
    setModalIsOpen(false);
    dispatch(userSignOut());
    history.push("/");
    clearTimeout(sessionTimeoutRef.current);
  };

  const stayActive = () => {
    setModalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
  };

  return (
    <div>
      <Modal
        title={<IntlMessages id="Idle.Timeout.Message" />}
        onOk={logout}
        okText={<IntlMessages id="Idle.Timeout.Logmeout" />}
        cancelText={<IntlMessages id="Idle.Timeout.Keepme" />}
        onCancel={stayActive}
        visible={modalIsOpen}
      ></Modal>
      <IdleTimer
        ref={idleTimerRef}
        timeout={timeoutMin * 60 * 1000}
        onIdle={onIdle}
      />
    </div>
  );
}

export default IdleTimerContainer;
