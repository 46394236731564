import * as Action from "../../constants/ActionTypes";
import * as Actions from "./index";
import axios from "util/Api";
import { batch } from "react-redux";
import React from "react";
import IntlMessages from "util/IntlMessages";
export const getProjectDetailsById = (id) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/Project/${id}`)
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.GET_PROJECT_DETAILS,
            payload: res.data.Payload,
          });
        } else {
          dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
