import React from "react";
import * as Action from "../../constants/ActionTypes";
import * as Actions from "../../appRedux/actions";

import _ from "lodash";
import axios from "util/Api";
import IntlMessages from "util/IntlMessages";

export const GetContractReviewByProjectId = (projectId) => (dispatch) => {
  return axios
    .get(
      `${Action.LOCALHOST}api/ContractReview/GetByProjectId?projectId=${projectId}`
    )
    .then((result) => {
      let attachement = result.data.Payload.lstAttachment;
      return Promise.all([
        dispatch(
          Actions.handleGetUploads(attachement, "PlannificationConception")
        ),

        dispatch({
          type: Action.GET_CONTRACT_REVIEW_DETAILS,
          payload: result.data.Payload,
        }),
      ]);
    })
    .catch((err) => {
      const errors = err.StatusDesc;
      return dispatch({
        type: Action.FETCH_ERROR,
        payload: errors,
      });
    });
};

export const AddContractReview = (values) => async (dispatch) => {
  try {
    const obj = {
      ...values,
    };

    const res = await axios.post(
      `${Action.LOCALHOST}api/ContractReview/Create`,
      obj
    );

    if (res.data.IsSuccess) {
      dispatch(
        Actions.fetchSuccess(
          <IntlMessages id="ContractReview.FormSaveSuccessful" />
        )
      );
      dispatch(Actions.GetHeaderByProjectId(values.ProjectId));
      dispatch(Actions.commonUpload("Project", parseInt(values.ProjectId)));

      return { success: true, id: res.data.Payload };
    } else {
      dispatch(
        Actions.fetchError(
          // res.data.ErrorMessage,
          <IntlMessages id="ContractReview.Failed" />
        )
      );
      return { success: false, id: null };
    }
  } catch (err) {
    dispatch(
      Actions.fetchError(
        // err.message,
        <IntlMessages id="ContractReview.Failed" />
      )
    );
    return { success: false, id: null };
  }
};

export const UpdateContractReview = (values) => async (dispatch) => {
  try {
    const obj = {
      ...values,
    };

    return axios
      .post(`${Action.LOCALHOST}api/ContractReview/Update`, obj)
      .then((res) => {
        if (res.data.IsSuccess) {
          dispatch(
            Actions.fetchSuccess(
              <IntlMessages id="ContractReview.FormUpdateSuccesful" />
            )
          );

          dispatch(
            Actions.commonUpload(
              "PlannificationConception",
              parseInt(values.PlanificationId)
            )
          );
          dispatch(Actions.GetHeaderByProjectId(values.ProjectId));
          Promise.all([
            dispatch(
              Actions.commonUpload("Project", parseInt(values.ProjectId))
            ),
          ]).then(() => {});
        } else {
          dispatch(
            Actions.fetchError(
              res.data.ErrorMessage,
              <IntlMessages id="Common.UpdateError" />
            )
          );
        }
      })
      .catch((error) => {
        dispatch(
          Actions.fetchError(
            error.message,
            <IntlMessages id="Common.UpdateError" />
          )
        );
      });
  } catch (err) {
    dispatch(
      Actions.fetchError(err.message, <IntlMessages id="Common.UpdateError" />)
    );
  }
};
