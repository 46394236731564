import * as Action from "../../constants/ActionTypes";
import moment from "moment";
const initState = {
  planificationTable: [],
  planificationTimeline: [],
  planificationDiscipline: [],
  data: null,
  editValue: null,
  disciplineVal: null,
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.SET_PLANIFICATION_FORM_DATA: {
      let temp = state.planificationTimeline;
      let obj = {
        planningTimelineId: 0,
        idplanificationconception: action.payload.PID,
        active: true,
        action: "Create",
        createdDate: moment(new Date()).format("YYYY-MM-DD"),
        createdBy: "",
        modifiedDate: moment(new Date()).format("YYYY-MM-DD"),
        modifiedBy: "",
        ...action.payload,
      };
      temp.push(obj);
      return {
        ...state,
        planificationTimeline: temp,
      };
    }
    case Action.ADD_EDIT_VALUE_PLANIFICATION_DISCIPLINE: {
      return {
        ...state,
        disciplineVal: action.payload,
      };
    }
    case Action.RESET_PLANIFICATION_FORM: {
      return {
        ...state,
        planificationTimeline: [],
        planificationDiscipline: [],
        data: null,
        editValue: null,
        disciplineVal: null,
      };
    }
    case Action.ADD_EDIT_VALUE_PLANIFICATION: {
      return {
        ...state,
        editValue: action.payload,
      };
    }
    case Action.UPDATE_PLANIFICATION_FORM_DATA: {
      return {
        ...state,
        planificationTimeline: action.payload,
      };
    }
    case Action.UPDATE_PLANIFICATION_DISCIPLINE: {
      return {
        ...state,
        planificationDiscipline: action.payload,
      };
    }
    case Action.SET_PLANIFICATION_DISCIPLINE: {
      let temp = state.planificationDiscipline;
      let obj = {
        planificationDisciplineid: 0,
        idplanificationconception: action.payload.PID,
        active: true,
        action: "Create",
        createdDate: moment(new Date()).format("YYYY-MM-DD"),
        createdBy: "",
        modifiedDate: moment(new Date()).format("YYYY-MM-DD"),
        modifiedBy: "",
        ...action.payload,
      };
      temp.push(obj);
      return {
        ...state,
        planificationDiscipline: temp,
      };
    }
    case Action.GET_PLANIFICATION_BY_ID: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case Action.SET_PLANIFICATION_TABLE_DATA: {
      return {
        ...state,
        planificationTable: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default reducer;
