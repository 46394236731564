import * as Action from "../../constants/ActionTypes";

const initState = {
  Data: [],
  Opportunity :[],
  OpportunitiesByWeek :[],
  data:[],
  dataByDepartment:null,
  Opportunities:[],
  oppertunityAlerts:[],
  OpportunityById:null,
  OpportunitiesByTwoDays:[],
  MyOpportunities : [],
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.GET_OPPORTUNITY: {
      return {
        ...state,
        Opportunity: action.payload,
      };
    }
    case Action.GET_ALL_OPPORTUNITIES_BY_LAST_WEEK: {
      return {
        ...state,
        OpportunitiesByWeek: action.payload,
      };
    }
    case Action.GET_SPECIFIC_OPPORTUNITIES: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case Action.GET_OPPORTUNITIES_BY_DEPT: {
      return {
        ...state,
        dataByDepartment: action.payload,
      };
    }
    case Action.GET_ALL_OPPORTUNITIES: {
      return {
        ...state,
        Opportunities: action.payload,
      };
    }

    case Action.GET_OPPORTUNITIES_ALERTS: {
      return {
        ...state,
        oppertunityAlerts: action.payload,
      };
    }

    case Action.GET_OPPORTUNITIES_ORDER_DOCUMENT: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case Action.UPDATE_DECISION: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case Action.SET_OPPORTUNITY_BY_ID: {
      
      return {
        ...state,
        OpportunityById: action.payload,
      };
    }
    
    case Action.GET_ALL_OPPORTUNITIES_BY_LAST_TWO_DAYS: {
      return {
        ...state,
        OpportunitiesByTwoDays: action.payload,
      };
    }
    case Action.MY_OPPORTUNITIES: {
      return {
        ...state,
        MyOpportunities: action.payload,
      };
    }
    case Action.RESET_OPPORTUNITY: {
      return {
        ...state,
        Data: [],
        Opportunity :[],
        OpportunitiesByWeek :[],
        data:[],
        dataByDepartment:null,
        Opportunities:[],
        oppertunityAlerts:[],
        OpportunityById:null,
        OpportunitiesByTwoDays:[],
        MyOpportunities : [],
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default reducer;
