import React from "react";
import { Spin } from "antd";
import { useSelector } from "react-redux";
function Loader() {
  const loading = useSelector(({ commonData }) => commonData.loader);
  return loading ? (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        zIndex: 10000,
        backgroundColor: "rgba(255,255,255,.5",
      }}
    >
      <Spin spinning={loading} tip="Loading..." />
    </div>
  ) : null;
}

export default Loader;
