import * as Action from "../../constants/ActionTypes";
const initState = {
  ContractReviewDetails: null,
};
const reducer = (state = initState, action) => {
  switch (action.type) {
    case Action.GET_CONTRACT_REVIEW_DETAILS: {
      return {
        ...state,
        ContractReviewDetails: action.payload,
      };
    }
 
    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
