import React, { useState, useEffect } from "react";
import { Layout, Popover } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import IntlMessages from "util/IntlMessages";
import {
  switchLanguage,
  toggleCollapsedSideNav,
} from "../../appRedux/actions/Setting";
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import Auxiliary from "util/Auxiliary";
import AppsNavigation from "./AppsNavigation";
import UserProfile from "./UserProfile";
import decode from 'jwt-decode';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import axios from 'util/Api'

const { Header } = Layout;

const Topbar = () => {

  // let expiredToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3NmNlOGUxOC02ODc1LTQ2NzEtYTgwZS02N2Q4NzM1MjA5YTUiLCJ1c2VybmFtZSI6InBydWRodmkucmFqQHRlY25vdHJhLmNvbSIsInNhbWFhY2NvdW50IjoibGRhcCB0ZXN0IiwiZW1haWwiOiJwcnVkaHZpLnJhakB0ZWNub3RyYS5jb20iLCJlbXBsb3llZWlkIjoiNjAzNCIsImRpc3BsYXluYW1lIjoicHJ1ZGh2aSByYWoiLCJkZXBhcnRtZW50aWQiOiIwMC0wMTAiLCJleHAiOjE1ODg5NjYxNDEsImlzcyI6ImF4b3JleHBlcnRzLmNvbSIsImF1ZCI6ImF4b3JleHBlcnRzLmNvbSJ9.Xe29feLOOt4EzFN8uFNf4BQAIK04yafT9BSQWb_k-0E"
  // let decoded = decode(expiredToken);
  let decoded = decode(JSON.parse(localStorage.getItem("token")));
  var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCSeconds(decoded.exp);
  

  if(new Date(d) < new Date()){
    localStorage.clear();
    window.location.reload(true)
  }





  axios.defaults.headers.common['authorization'] =`bearer ${JSON.parse(localStorage.getItem("token"))}`

  const { locale, width, navCollapsed, navStyle } = useSelector(
    ({ settings }) => settings
  );
  //console.log("Sagaraaaaa");
  //console.log(locale);
  const { searchText, setSearchText } = useState("");
  const dispatch = useDispatch();
  const [selectedlanguage, setlanguage] = useState(locale);
  useEffect(() => {
    const data = localStorage.getItem("selectedlanguage");
    if (data) {
      var parseddata = JSON.parse(data);
      console.log("parseddata");
      console.log(parseddata);
      setlanguage(parseddata);
      dispatch(switchLanguage(parseddata));
    }
  }, "");
  useEffect(() => {
    localStorage.setItem("selectedlanguage", JSON.stringify(selectedlanguage));
  });
  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll" >
      <ul className="gx-sub-popover" >
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={(e) => {
              setlanguage(language);
              dispatch(switchLanguage(language));
            }}
          >
            {/* <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/> */}
            <span className="gx-language-text" >
              <IntlMessages id={language.name} />
            </span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };
  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
      <img alt="" src={require("assets/images/logo_FNX.svg")} style={{width:80, height:80}}/> 
      </Link>

      {/* <SearchBox
        styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
        placeholder="Search in app..."
        onChange={updateSearchChatUser}
        value={searchText}
      /> */}
      <ul className="gx-header-notifications gx-ml-auto">
        {/* <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={
              <SearchBox
                styleName="gx-popover-search-bar"
                placeholder="Search in app..."
                onChange={updateSearchChatUser}
                value={searchText}
              />
            }
            trigger="click"
          >
            <span className="gx-pointer gx-d-block">
              <i className="icon icon-search-new" />
            </span>
          </Popover>
        </li> */}
        {/* {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-notify">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<AppNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-d-block">
                  <i className="icon icon-notification" />
                </span>
              </Popover>
            </li>

            <li className="gx-msg">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<MailNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-status-pos gx-d-block">
                  <i className="icon icon-chat-new" />
                  <span className="gx-status gx-status-rtl gx-small gx-orange" />
                </span>
              </Popover>
            </li>
          </Auxiliary>
        )} */}
        {/* <li>
          <AppsNavigation />
        </li> */}
        <li className="gx-language" >
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={languageMenu()}
            trigger="click"
            
          >
            <span className="gx-pointer gx-flex-row gx-align-items-center"  >
              <span className="gx-pl-2 gx-language-name" >
                {/* {" "} */}
                <IntlMessages id={locale.name} />
              </span>
              <i className="icon icon-chevron-down gx-pl-2" />
            </span>
          </Popover>
        </li>
        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </Auxiliary>
        )}
      </ul>
    </Header>
  );
};

export default Topbar;
