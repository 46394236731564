import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = ({ match }) => {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={`${match.url}Dashboard`}
          component={asyncComponent(() => import("./SamplePage"))}
        />
        <Route
          path={`${match.url}opportunities/newform`}
          component={asyncComponent(() => import("./Opportunities/NewForm"))}
        />
        <Route
          path={`${match.url}opportunities/lastsevendays`}
          component={asyncComponent(() =>
            import("./Opportunities/LastSevenDays")
          )}
        />
        <Route
          path={`${match.url}opportunities/lasttwodays`}
          component={asyncComponent(() =>
            import("./Opportunities/LastTwoDays")
          )}
        />
        <Route
          path={`${match.url}opportunities/all`}
          component={asyncComponent(() => import("./Opportunities/All"))}
        />
        <Route
          path={`${match.url}opportunities/alarm`}
          component={asyncComponent(() => import("./Opportunities/Alarm"))}
        />
        <Route
          path={`${match.url}opportunities/myopportunities`}
          component={asyncComponent(() =>
            import("./Opportunities/MyOpportunities")
          )}
        />
        <Route
          path={`${match.url}opportunities/bydepartment`}
          component={asyncComponent(() =>
            import("./Opportunities/ByDepartment")
          )}
        />
        <Route
          path={`${match.url}project/create/:tab?`}
          component={asyncComponent(() => import("./Projects/Router/"))}
        />
        <Route
          path={`${match.url}myapprovals`}
          component={asyncComponent(() => import("./Projects/MyApprovals"))}
        />

        <Route
          path={`${match.url}project/approval`}
          component={asyncComponent(() => import("./Projects/Approvals"))}
        />
        <Route
          path={`${match.url}project/projectView/:tab?`}
          component={asyncComponent(() =>
            import("./Projects/Router/index_view.js")
          )}
        />
        <Route
          path={`${match.url}project/accounting`}
          component={asyncComponent(() => import("./Projects/Accounting"))}
        />
        <Route
          path={`${match.url}project/fulllist`}
          component={asyncComponent(() => import("./Projects/Fulllist"))}
        />
        <Route
          path={`${match.url}project/listbycharge`}
          component={asyncComponent(() => import("./Projects/ListCharge"))}
        />
        <Route
          path={`${match.url}project/listbycustomer`}
          component={asyncComponent(() => import("./Projects/ListByCustomers"))}
        />
        <Route
          path={`${match.url}project/Map`}
          component={asyncComponent(() => import("./Projects/Map"))}
        />
        <Route
          path={`${match.url}project/listbyphaseoffer`}
          component={asyncComponent(() =>
            import("./Projects/ListByPhaseOffer")
          )}
        />
        {/* <Route
        path={`${match.url}project/listbycustomer`}
        component={asyncComponent(() => import("./Projects/Approvals"))}
      /> */}
        {/* <Route
        path={`${match.url}offer/create`}
        component={asyncComponent(() => import("./Offers/Create"))}
      /> */}
      </Switch>
    </div>
  );
};

export default App;
