import * as Action from "../../constants/ActionTypes";
import axios from "util/Api";
import uuid from "react-uuid";
import * as Actions from "./index";
import React from "react";
import IntlMessages from "util/IntlMessages";
export const addEnginneringForm = (payload) => {
  return (dispatch, getState) => {
    const { data } = getState().Planification;
    return dispatch({
      type: Action.SET_PLANIFICATION_FORM_DATA,
      payload: { ...payload, PID: data ? data.IdPlanificationConception : 0 },
    });
  };
};
export const updateEngineeringForm = (payload) => {
  return {
    type: Action.UPDATE_PLANIFICATION_FORM_DATA,
    payload,
  };
};
export const updateDisciplineForm = (payload) => {
  return {
    type: Action.UPDATE_PLANIFICATION_DISCIPLINE,
    payload,
  };
};
export const addDisciplineForm = (payload) => {
  return (dispatch, getState) => {
    const { data } = getState().Planification;
    return dispatch({
      type: Action.SET_PLANIFICATION_DISCIPLINE,
      payload: { ...payload, PID: data ? data.IdPlanificationConception : 0 },
    });
  };
};
export const addEditValue = (id) => {
  return (dispatch, getState) => {
    const { planificationTimeline } = getState().Planification;
    let temp = planificationTimeline.findIndex((e) => e.id === id);
    let value = planificationTimeline[temp];
    dispatch({
      type: Action.ADD_EDIT_VALUE_PLANIFICATION,
      payload: { ...value, index: temp },
    });
  };
};
export const addEditValueDiscipline = (id) => {
  return (dispatch, getState) => {
    const { planificationDiscipline } = getState().Planification;
    let temp = planificationDiscipline.findIndex((e) => e.id === id);
    let value = planificationDiscipline[temp];
    dispatch({
      type: Action.ADD_EDIT_VALUE_PLANIFICATION_DISCIPLINE,
      payload: { ...value, index: temp },
    });
  };
};
export const resetDisciplineForm = () => {
  return {
    type: Action.ADD_EDIT_VALUE_PLANIFICATION_DISCIPLINE,
    payload: null,
  };
};
export const editTimelineData = (val) => {
  return (dispatch, getState) => {
    const { planificationTimeline } = getState().Planification;
    let temp = planificationTimeline.findIndex((e) => e.id === val.id);
    let data = planificationTimeline;
    data[temp] = { ...data[temp], ...val, action: "Edit" };
    dispatch(updateEngineeringForm(data));
  };
};
export const editDisciplineData = (val) => {
  return (dispatch, getState) => {
    const { planificationDiscipline } = getState().Planification;
    let temp = planificationDiscipline.findIndex((e) => e.id === val.id);
    let data = planificationDiscipline;
    data[temp] = { ...data[temp], ...val, action: "Edit" };
    dispatch(updateDisciplineForm(data));
  };
};
export const deleteTimeLineData = (id) => {
  return (dispatch, getState) => {
    const { planificationTimeline } = getState().Planification;
    let temp = planificationTimeline.findIndex((e) => e.id === id);
    let data = planificationTimeline;
    data[temp].action = "Delete";
    if (data[temp].planningTimelineId === 0) {
      data = data.filter((val) => val.id !== id);
    }
    dispatch(updateEngineeringForm(data));
  };
};
export const deleteDiscispline = (id) => {
  return (dispatch, getState) => {
    const { planificationDiscipline } = getState().Planification;
    let temp = planificationDiscipline.findIndex((e) => e.id === id);
    let data = planificationDiscipline;
    data[temp].action = "Delete";
    if (data[temp].planificationDisciplineid === 0) {
      data = data.filter((val) => val.id !== id);
    }
    dispatch(updateDisciplineForm(data));
  };
};
export const getPlanificationTableData = (ID) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/Planification/Project/${ID}`)
      .then((res) => {
        if (res.data.Payload) {
          return dispatch({
            type: Action.SET_PLANIFICATION_TABLE_DATA,
            payload: res.data.Payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const getPlanificationByID = (ID) => {
  return (dispatch) => {
    return axios
      .get(`${Action.LOCALHOST}api/Planification/${ID}`)
      .then((res) => {
        if (res.data.Payload) {
          console.log("Response ", res.data);
          let attachement = res.data.Payload[0].lstAttachment;
          return Promise.all([
            dispatch(
              Actions.handleGetUploads(attachement, "PlannificationConception")
            ),

            dispatch({
              type: Action.GET_PLANIFICATION_BY_ID,
              payload: res.data.Payload.length > 0 ? res.data.Payload[0] : null,
            }),
            dispatch(
              updateDisciplineForm(
                res.data.Payload.length > 0
                  ? res.data.Payload[0].PlanificationDiscipline.map((val) => {
                      return { ...val, id: uuid() };
                    })
                  : []
              )
            ),
            dispatch(
              updateEngineeringForm(
                res.data.Payload.length > 0
                  ? res.data.Payload[0].PlanificationTimeline.map((val) => {
                      return { ...val, id: uuid() };
                    })
                  : []
              )
            ),
          ]);
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const savePlanificationData = (val) => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;
    const { planificationTimeline } = getState().Planification;
    const { planificationDiscipline } = getState().Planification;

    let obj = {
      ...val,
      IdProjet: projectGotById !== undefined ? projectGotById.IdProjet : 0,
      IdPlanificationConception: 0,
      PlanificationTimeline: planificationTimeline,
      PlanificationDiscipline: planificationDiscipline,
      NoPlanification: parseInt(val.NoPlanification),
      BudgetInitialHonoraires: parseInt(val.BudgetInitialHonoraires),
    };
    return axios
      .post(`${Action.LOCALHOST}api/Planification/Create`, obj)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(
            getPlanificationTableData(
              projectGotById !== undefined ? projectGotById.IdProjet : 0
            )
          );
          dispatch(
            Actions.commonUpload("PlannificationConception", res.data.Payload)
          );
          dispatch(Actions.GetHeaderByProjectId(obj.IdProjet));
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.DataSuccess" />)
          );
          return true;
        } else {
          dispatch(
            Actions.fetchError(null, <IntlMessages id="Common.DataSaveError" />)
          );
          return false;
        }
      })
      .catch((err) => {
        dispatch(
          Actions.fetchError(err.message, <IntlMessages id="Common.DataFail" />)
        );
        return false;
      });
  };
};
export const deletePlanification = (val) => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;

    return axios
      .delete(`${Action.LOCALHOST}api/Planification/${val}`)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(
            Actions.GetHeaderByProjectId(
              projectGotById !== undefined ? projectGotById.IdProjet : 0
            )
          );
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.SuccessDelete" />)
          );
          return dispatch(
            getPlanificationTableData(
              projectGotById !== undefined ? projectGotById.IdProjet : 0
            )
          );
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        console.log(err);
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const updataPlanification = (val) => {
  return (dispatch, getState) => {
    const { projectGotById } = getState().createProject;
    const { planificationTimeline } = getState().Planification;
    const { planificationDiscipline } = getState().Planification;
    const { data } = getState().Planification;

    let obj = {
      ...data,
      ...val,
      IdProjet: projectGotById !== undefined ? projectGotById.IdProjet : 0,
      PlanificationTimeline: planificationTimeline,
      PlanificationDiscipline: planificationDiscipline,
      NoPlanification: parseInt(val.NoPlanification),
      BudgetInitialHonoraires: parseInt(val.BudgetInitialHonoraires),
    };
    return axios
      .put(`${Action.LOCALHOST}api/Planification/Update`, obj)
      .then((res) => {
        if (res.data.Payload) {
          dispatch(Actions.GetHeaderByProjectId(obj.IdProjet));
          dispatch(
            Actions.commonUpload(
              "PlannificationConception",
              parseInt(res.data.Payload)
            )
          );
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.DataSuccess" />)
          );
          return true;
        } else {
          dispatch(
            Actions.fetchError(null, <IntlMessages id="Common.DataSaveError" />)
          );
          return false;
        }
      })
      .catch((err) => {
        dispatch(
          Actions.fetchError(err.message, <IntlMessages id="Common.DataFail" />)
        );
        return false;
      });
  };
};
export const resetPlanificationForm = () => {
  return {
    type: Action.RESET_PLANIFICATION_FORM,
  };
};
export const ResetInnerModalIdentification = () => {
  return {
    type: Action.ADD_EDIT_VALUE_PLANIFICATION,
    payload: null,
  };
};
